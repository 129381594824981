<form class="example-form">
  <mat-form-field class="example-full-width">
    <input type="text"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto"
           [disabled] = disabled
    >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onWarehouseSelected($event)" [displayWith]="displayFn">
      @for (option of filteredOptions | async; track option.id) {
        <mat-option [value]="option">{{option.title}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
