import { ReturnData } from '../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TransformEntityToErp } from '../models/tranformentitytoerp';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransformationService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public quoteToOrder(quoteId: number): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'transfomation/quote/' + quoteId
    );
  }

  public orderToPicking(orderId: number): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'transfomation/order/' + orderId
    );
  }

  public purchasingOrderToReceiving(orderId: number): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'transfomation/purchasingorder/' + orderId
    );
  }

  public leadToAccount(leadId: number): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'transfomation/lead/' + leadId
    );
  }

  public entityToErp(
    transformEntityToErp: TransformEntityToErp
  ): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl + 'transfomation/entitytoerp',
      transformEntityToErp
    );
  }
}
