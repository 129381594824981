<div class="buttonPanel mat-elevation-z3">
  <button mat-button [mat-dialog-close]="null">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!(layoutService.isHandset$ | async)">{{
      'Generic.back' | translate
      }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="startTranformation()">
    <mat-icon>save</mat-icon>
    <span *ngIf="!(layoutService.isHandset$ | async)">{{
      'Generic.save' | translate
      }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div class="mainDiv">
  <div *ngIf="showTransformationOptions" class="transformation-container">
    <h3 style="text-align: center;">{{ 'Generic.chooseTransformationType' | translate }}</h3>
    <div class="button-group">
      <button mat-raised-button color="primary" (click)="showTransformation('erp')" class="centered-button">
        {{ 'Generic.transformToERP' | translate }}
      </button>
      <button *ngIf="isOrderEntity()" mat-raised-button color="primary" (click)="showTransformation('pickingorder')"
        class="centered-button">
        {{ 'Generic.transformToPickingOrder' | translate }}
      </button>
      <button *ngIf="isPurchasingOrderEntity()" mat-raised-button color="primary" (click)="showTransformation('receivinglist')"
        class="centered-button">
        {{ 'Generic.transformToReceivingList' | translate }}
      </button>
    </div>
  </div>
</div>

  <!-- ERP transformation form -->
  <div *ngIf="selectedTransformationType === 'erp'">
    <mat-form-field class="docFld">
      <mat-label>{{ 'ERPSpdoctype.doc_type' | translate }}</mat-label>
      <mat-select [(ngModel)]="transformEntityToErp.spdoctype_id">
        <mat-option *ngFor="let type of spdoctypes" [value]="type.id">
          {{ type.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <ng-container *ngIf="isProductionOrderEntity()">
        <mat-form-field class="docFld">
          <mat-label>{{ 'PurchasingOrder.qty' | translate }}</mat-label>
          <input matInput type="number" [(ngModel)]="desired_quantity" />
        </mat-form-field>
      </ng-container>

    <div class="docFld" *ngIf="transformEntityToErp.spdoctype_id">
      {{ 'ERP.tranformation_progress' | translate }}
      {{ selectedSpdocTitle() }}

      <div style="margin-bottom: 20px">
        <mat-progress-bar mode="determinate" [value]="percetange"></mat-progress-bar>
      </div>
      <div *ngFor="let resp of responseTransformations">
        {{ 'Generic.transformation' | translate }}
        <strong>#{{ resp.entity_id }} </strong>
        <span *ngIf="resp.success">
          <button mat-button color="primary" (click)="previewDoc(resp.doc_id, resp.type)">
            <mat-icon>edit</mat-icon>OK
          </button>
        </span>
        <span *ngIf="!resp.success"> Error {{ resp.message }}</span>
      </div>
    </div>
  </div>
