import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseFilter, SpdocFilter } from 'src/app/common/filters';
import { PagedData, ReturnData } from 'src/app/model';
import { ERPSpdoc } from 'src/app/models/erpspdoc';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErpSpdocService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getWhSpdocs(filters: SpdocFilter): Observable<PagedData<ERPSpdoc>> {

    let queryFrom: string = (filters.from) ? '&from=' + filters.from : '';
    let queryTo: string = (filters.to) ? '&to=' + filters.to : '';
    let queryTransactor: string = (filters.transactor_id) ? '&transactor=' + filters.transactor_id : '';
    let querySpdocType: string = (filters.spdocType) ? '&spdocType=' + filters.spdocType : '';
    let queryMinTotalPrice: string = (filters.minTotalPrice != null) ? '&minTotalPrice=' + filters.minTotalPrice : '';
    let queryMaxTotalPrice: string = (filters.maxTotalPrice != null) ? '&maxTotalPrice=' + filters.maxTotalPrice : '';
    let queryStrValue: string = (filters.strValue != null) ? '&strValue=' + filters.strValue : '';
    const query = `${queryFrom}${queryTo}${queryTransactor}${querySpdocType}${queryMinTotalPrice}${queryMaxTotalPrice}${queryStrValue}`;
    return this.get<PagedData<ERPSpdoc>>(this.apiUrl + 'erp/spwhdocs?page=' + filters.currentPage + "&pageSize=" + filters.pageSize + query);
  }

  public getFinSpdocs(filters: SpdocFilter): Observable<PagedData<ERPSpdoc>> {

    let queryFrom: string = (filters.from) ? '&from=' + filters.from : '';
    let queryTo: string = (filters.to) ? '&to=' + filters.to : '';
    let queryTransactor: string = (filters.transactor_id) ? '&transactor=' + filters.transactor_id : '';
    let querySpdocType: string = (filters.spdocType) ? '&spdocType=' + filters.spdocType : '';
    let queryMinTotalPrice: string = (filters.minTotalPrice != null) ? '&minTotalPrice=' + filters.minTotalPrice : '';
    let queryMaxTotalPrice: string = (filters.maxTotalPrice != null) ? '&maxTotalPrice=' + filters.maxTotalPrice : '';
    let queryStrValue: string = (filters.strValue != null) ? '&strValue=' + filters.strValue : '';

    const query = `${queryFrom}${queryTo}${queryTransactor}${querySpdocType}${queryMinTotalPrice}${queryMaxTotalPrice}${queryStrValue}`;
    return this.get<PagedData<ERPSpdoc>>(this.apiUrl + 'erp/spfindocs?page=' + filters.currentPage + "&pageSize=" + filters.pageSize + query);
  }

  public getDocById(doc_id: number): Observable<ERPSpdoc> {
    return this.get<ERPSpdoc>(this.apiUrl + 'erp/spdoc/' + doc_id);
  }

  public saveSpdoc(spdoc: ERPSpdoc): Observable<ReturnData<ERPSpdoc>> {
    return this.post<ReturnData<ERPSpdoc>>(this.apiUrl + 'erp/spdocs', spdoc);
  }

  public exportDocPdf(doc_id: number): Observable<any> {
    return this.getFile<any>(this.apiUrl + 'erp/export/spdocs/' + doc_id);
  }

  public multiReplicateSpdocItem(spdocId: string, amount: number): Observable<ReturnData<number[]>> {
    return this.get<ReturnData<number[]>>(this.apiUrl + 'erp/copy-spdoc-item/erp_document/' + spdocId +'/' + amount);
  }

  public exportGridToExcel(filters: BaseFilter, docCode: string, gridColumns: string[]) {
    return this.getWithPostFile(this.apiUrl + 'erp/doc/grid-export/' + docCode, {
      ...filters,
      gridColumns,
    });
  }

}
