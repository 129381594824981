<div class="buttonPanel mat-elevation-z3">
  <span>
    <button mat-button [mat-dialog-close]="null">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.back' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>

  <span>
    <button
      mat-button
      (click)="save()"
      [disabled]="approval.assignees.length == 0"
    >
      <mat-icon>save</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.save' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div mat-dialog-content class="content">
  <div
    class="mainArea"
    [class.mainArea]="!(isHandset$ | async)"
    [class.mainAreaM]="isHandset$ | async"
  >
    <div>
      <mat-label
        ><strong>{{ 'Approvals.to' | translate }}:</strong></mat-label
      >
      <button mat-icon-button (click)="selectUserDiag()">
        <mat-icon color="primary">person_search</mat-icon>
      </button>

      <div class="flexdiv section">
        <div *ngIf="approval.assignees.length > 0" class="flexdiv">
          <span>
            <app-show-avatar
              [USER]="approval.assignees[0].assignee"
            ></app-show-avatar>
          </span>
          <span
            class="text-alternate-warn"
            style="margin-left: 15px; margin-top: 8px"
            >{{ approval.assignees[0].assignee.firstname }}
            {{ approval.assignees[0].assignee.lastname }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div>
        <mat-label
          ><strong>{{ 'Approvals.request' | translate }}:</strong></mat-label
        >
      </div>
      <div>
        <mat-form-field class="signatoryMessage full-width">
          <textarea matInput [(ngModel)]="approval.message"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="section">
      <mat-label
        ><strong>{{ 'Approvals.entityItem' | translate }}:</strong></mat-label
      >
      <button
        *ngIf="entityMenuSelectorLink?.menu"
        mat-icon-button
        [matMenuTriggerFor]="entityMenuSelectorLink?.menu"
        [matTooltip]="'Generic.search' | translate"
      >
        <mat-icon color="primary">add_link</mat-icon>
      </button>
      <div>
        <span *ngIf="approval.entdata_id"
          >#{{ approval.entdata_id }} {{ itemLabel }}</span
        >
      </div>
    </div>
  </div>
</div>

<app-entity-menu-selector
  #addlinkMenu
  ICON="add_link"
  (SELECTENTITY)="selectEntityIDiag($event)"
>
</app-entity-menu-selector>
