import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { Entdata, Entity, Field } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-single-entity-filter',
  templateUrl: './single-entity-filter.component.html',
  styleUrls: ['./single-entity-filter.component.css'],
})
export class SingleEntityFilterComponent implements OnInit {
  @Input() ITEM: Field;
  @Input() outputResult: boolean = false;
  @Output() entityIdResult = new EventEmitter<number>();
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.ITEM.for_entity =
      this.ITEM.entfieldtype_id == 20 ? 'product' : this.ITEM.for_entity;
  }

  public selectEntityItemDiag() {
    console.log(this.ITEM);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.ITEM.for_entity,
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        if (!this.outputResult) {
          this.ITEM.search_from = result.id;
        }else{
          this.entityIdResult.emit(result.id);
        }
      }
    });
  }
}
