@if (ENTITY) {
  <div style="margin: 0 10px">
    <div>
      <div class="globalFilterBar">
        <section
          class="ownerFilter"
          [ngClass]="{
            baseFilters: !(isHandset$ | async),
            'baseFilters-M': (isHandset$ | async)
          }"
        >
          <span>Owner</span>
          <app-user-filter
            (selectUserAction)="selectOwnerFiler($event)"
            [SHOWUSER]="!!FILTERS.ownerId"
          ></app-user-filter>
          @if (FILTERS.ownerId) {
            <button mat-icon-button color="warn" (click)="clearOwnerId()">
              <mat-icon>clear</mat-icon>
            </button>
          }
        </section>

        <section
          [ngClass]="{
            baseFilters: !(isHandset$ | async),
            'baseFilters-M': (isHandset$ | async)
          }"
        >
          <mat-form-field class="fullwidth">
            <mat-label># ID</mat-label>
            <input matInput type="number" [(ngModel)]="FILTERS.itemId" />
          </mat-form-field>
          @if (FILTERS.itemId) {
            <button mat-icon-button color="warn" (click)="clearItemId()">
              <mat-icon>clear</mat-icon>
            </button>
          }
        </section>

        <section
          [ngClass]="{
            baseFilters: !(isHandset$ | async),
            'baseFilters-M': (isHandset$ | async)
          }"
        >
          <mat-form-field class="fullwidth">
            <mat-label>{{ 'Generic.createdDate' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Start date"
                [(ngModel)]="FILTERS.createdAtSearchDateStart"
              />
              <input
                matEndDate
                placeholder="End date"
                [(ngModel)]="FILTERS.createdAtSearchDateEnd"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          @if (
            FILTERS.createdAtSearchDateStart || FILTERS.createdAtSearchDateEnd
          ) {
            <button
              mat-icon-button
              color="warn"
              (click)="clearCreatedAtSearchDate()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          }
        </section>
        <section
          [ngClass]="{
            baseFilters: !(isHandset$ | async),
            'baseFilters-M': (isHandset$ | async)
          }"
        >
          <mat-form-field class="fullwidth">
            @if (FILTERS) {
              <mat-select
                [(ngModel)]="FILTERS.trashed"
                (ngModelChange)="changeFilter()"
              >
                <mat-option [value]="2">
                  {{ 'Generic.all' | translate }}
                </mat-option>
                <mat-option [value]="0">
                  {{ 'Generic.active' | translate }}
                </mat-option>
                <mat-option [value]="1">
                  {{ 'Generic.deactivate' | translate }}
                </mat-option>
              </mat-select>
            }
          </mat-form-field>
        </section>

        @if (isHandset$ | async) {
          <div class="baseFilters-M">
            <app-saved-filters
              [ENTITY]="ENTITY"
              [FILTERS]="FILTERS"
              (applyFilter)="applySavedFilter($event)"
              (saveFilters)="saveFilters($event)"
            ></app-saved-filters>
          </div>
        }

        @for (attr of prefs; track attr.id) {
          <section
            [ngClass]="{
              baseFilters: !(isHandset$ | async),
              'baseFilters-M': (isHandset$ | async)
            }"
          >
            @if (attr.field_specs.fld_code === 'singleEntity') {
              <mat-form-field style="width: 200px">
                <mat-label>{{ attr | translateFieldLabel }}</mat-label>
                <input matInput type="number" [(ngModel)]="attr.search_from" />
              </mat-form-field>
              <app-single-entity-filter [ITEM]="attr">
              </app-single-entity-filter>
            }

            @if (attr.field_specs.fld_code === 'scale') {
              <div style="display: flex; align-items: center">
                <div class="fullwidth">
                  <mat-label class="fullwidth">{{
                    attr | translateFieldLabel
                  }}</mat-label>
                  <mat-slider
                    style="width: 220px"
                    max="5"
                    min="1"
                    step="1"
                    discrete
                    color="primary"
                    showTickMarks
                  >
                    <input matSliderThumb [(ngModel)]="attr.search_from" />
                  </mat-slider>
                  <mat-slider
                    style="width: 220px"
                    max="5"
                    min="1"
                    step="1"
                    discrete
                    color="primary"
                    showTickMarks
                  >
                    <input matSliderThumb [(ngModel)]="attr.search_to" />
                  </mat-slider>
                </div>
              </div>
            }

            @if (attr.field_specs.fld_code === 'user') {
              <section class="ownerFilter">
                <span style="margin-top: 10px">{{
                  attr | translateFieldLabel
                }}</span>
                <app-user-filter
                  [search_from]="attr.search_from"
                  [ITEM]="attr"
                  [SHOWUSER]="!!attr.search_from"
                ></app-user-filter>
              </section>
            }

            @if (attr.field_specs.fld_code === 'number') {
              <div>
                <div style="width: 250px">
                  <mat-form-field>
                    <mat-label
                      >{{ attr | translateFieldLabel }} - Από</mat-label
                    >
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="attr.search_from"
                    />
                  </mat-form-field>
                </div>
                <div style="width: 250px">
                  <mat-form-field>
                    <mat-label
                      >{{ attr | translateFieldLabel }} - Έως</mat-label
                    >
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="attr.search_to"
                      (ngModelChange)="autoFillFrom(attr)"
                    />
                  </mat-form-field>
                </div>
              </div>
            }

            @if (
              attr.field_specs.fld_code === 'text' ||
              attr.field_specs.fld_code === 'email' ||
              attr.field_specs.fld_code === 'tel'
            ) {
              <mat-form-field class="fullwidth">
                <mat-label>{{ attr | translateFieldLabel }}</mat-label>
                <input matInput [(ngModel)]="attr.search_from" />
              </mat-form-field>
            }

            @if (attr.field_specs.fld_code === 'month') {
              <section class="fullwidth">
                <app-month-filter #monthFilter [ITEM]="attr"></app-month-filter>
              </section>
            }

            @if (attr.field_specs.fld_code === 'week') {
              <section class="fullwidth">
                <app-week-filter #weekFilter [ITEM]="attr"></app-week-filter>
              </section>
            }

            @if (attr.field_specs.fld_code === 'dropdown') {
              <section class="fullwidth">
                <app-dropdown-filter [ITEM]="attr"></app-dropdown-filter>
              </section>
            }

            @if (attr.field_specs.fld_code === 'datetime') {
              <div>
                <div style="width: 250px">
                  <app-datetime-filter
                    [FROMORTO]="'from'"
                    [ITEM]="attr"
                  ></app-datetime-filter>
                </div>
                <div style="width: 250px">
                  <app-datetime-filter
                    [FROMORTO]="'το'"
                    [ITEM]="attr"
                    (changeDate)="autoFillFrom(attr)"
                  >
                  </app-datetime-filter>
                </div>
              </div>
            }

            @if (attr.field_specs.fld_code === 'checkbox') {
              <div class="fullwidth">
                <span style="margin-top: 10px; margin-right: 2px">{{
                  attr | translateFieldLabel
                }}</span>
                <app-checkbox-filter [ITEM]="attr"> </app-checkbox-filter>
              </div>
            }

            @if (attr.field_specs.fld_code === 'productlist') {
              <section style="display: flex">
                <mat-form-field style="width: 140px">
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="attr.search_from"
                  />
                </mat-form-field>
                <app-single-entity-filter [ITEM]="attr">
                </app-single-entity-filter>
              </section>
            }

            @if (attr.search_to || attr.search_from) {
              <button
                mat-icon-button
                color="warn"
                (click)="clearSearchValues(attr)"
              >
                <mat-icon>clear</mat-icon>
              </button>
            }
          </section>
        }

        @if (
          ENTITY.entity_code === 'tasks' &&
          filterService.creationDateRangeFilterOnBar()
        ) {
          <section
            [ngClass]="{
              baseFilters: !(isHandset$ | async),
              'baseFilters-M': (isHandset$ | async)
            }"
          >
            <mat-form-field style="width: 252px">
              <mat-label>{{
                'Generic.taskStartDateRangeFilter' | translate
              }}</mat-label>
              <mat-select
                [(ngModel)]="filterService.creationDateRangeFilter"
                (ngModelChange)="applyCreationDateRangeFilter()"
              >
                <mat-option value="currentWeek">{{
                  'Generic.currentWeek' | translate
                }}</mat-option>
                <mat-option value="currentMonth">{{
                  'Generic.currentMonth' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div style="width: 60px">
              @if (filterService.creationDateRangeFilter() !== '') {
                <button
                  mat-icon-button
                  color="primary"
                  (click)="clearCreationDateRangeFilter()"
                >
                  <mat-icon color="warn">clear</mat-icon>
                </button>
              }
            </div>
          </section>
        }

        @if (!(isHandset$ | async)) {
          <button
            mat-button
            color="primary"
            style="height: 56px"
            (click)="toogleFilters = !toogleFilters"
          >
            @if (toogleFilters) {
              <mat-icon>keyboard_arrow_right</mat-icon>
            } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
            }
            <mat-icon>filter_alt</mat-icon>
          </button>
        }
      </div>
      @if (!(isHandset$ | async)) {
        <div [hidden]="toogleFilters">
          <div style="width: 100%; display: flex; margin-bottom: 50px">
            <div class="filterCard">
              <app-entity-field-filters
                [ENTITY]="ENTITY"
                [FILTERS]="FILTERS"
                [prefs]="prefs"
                [weekFilters]="weekFilters"
                [monthFilters]="monthFilters"
                (modifyPrefs)="refreshPrefs($event)"
              ></app-entity-field-filters>
            </div>
            <div class="savedFiltersCard">
              <app-saved-filters
                [ENTITY]="ENTITY"
                [FILTERS]="FILTERS"
                (applyFilter)="applySavedFilter($event)"
                (saveFilters)="saveFilters($event)"
              ></app-saved-filters>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
