import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Injector, Signal } from '@angular/core';
import { SpinnerType } from '../common/enums';
import { BaseFilter, EntityItemFilter } from '../common/filters';
import {
  BulkItemsBatch,
  DocumentFile,
  Entconnection,
  Entdata,
  Entity,
  Field,
  FieldType,
  PagedData,
  Post,
  ReturnData,
  SavedColumns,
  SavedFilter,
  SearchEntResults,
  User,
} from '../model';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { take } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class EntityService extends BaseService {
  public CHACHEDENTITES: Entity[] = [];
  customEntities: Entity[] = [];
  private helper = new JwtHelperService();

  selectedItemIds = new BehaviorSubject<number[]>([]);
  getEntityListWithSalesmanFilters = new Subject<boolean>();
  storedEntities$ = new BehaviorSubject<Entity[]>([]);
  storedUserEntities$ = new BehaviorSubject<Entity[]>([]);
  storedActiveCustomEntities$ = new BehaviorSubject<Entity[]>([]);
  entitySelectorFieldCode = '';
  private readonly injector = inject(Injector);

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.getCachedEntityList();
  }

  public getEntityDataList(
    filters: BaseFilter,
    entity: string
  ): Observable<PagedData<any>> {
    return this.post<PagedData<any>>(
      this.apiUrl + 'entity/' + entity + '/list?page=' + filters.currentPage,
      filters
    );
  }

  public getEntityDataListNoSpinner(
    filters: BaseFilter,
    entity: string
  ): Observable<PagedData<any>> {
    return this.post<PagedData<any>>(
      this.apiUrl + 'entity/' + entity + '/list?page=' + filters.currentPage,
      filters,
      SpinnerType.None
    );
  }

  public getFldsEntityByName(entity: string): Observable<Entity> {
    return this.get<Entity>(this.apiUrl + 'entity/' + entity);
  }

  public getFldsEntityByNameNoSpinner(entity: string): Observable<Entity> {
    return this.get<Entity>(this.apiUrl + 'entity/' + entity, SpinnerType.None);
  }

  public getFldsEntityByNameByCompany(
    entity: string,
    companyId: number
  ): Observable<any> {
    return this.get<any>(
      this.apiUrl + 'admin/entities/fields/' + entity + '/' + companyId
    );
  }

  public geEntityItem(entity: string, id: string): Observable<Entdata> {
    return this.get<Entdata>(this.apiUrl + 'entity/item/' + entity + '/' + id);
  }

  public getEntityList(): Observable<any[]> {
    return this.get<any[]>(this.apiUrl + 'entities');
  }

  public getCachedEntityList() {
    if (this.CHACHEDENTITES.length === 0) {
      this.getEntityList()
        .pipe(take(1))
        .subscribe((x) => {
          this.CHACHEDENTITES = x;
          this.customEntities = this.CHACHEDENTITES.filter(
            (entity) => entity.isCore == 0 && entity.isActive == 1
          );
        });
    }
  }

  public getEntityListForAdmin(companyId: string): Observable<any[]> {
    return this.get<any[]>(this.apiUrl + 'admin/entities/' + companyId);
  }

  public newEntity(entity: Entity): Observable<ReturnData<Entity[]>> {
    return this.post<ReturnData<Entity[]>>(
      this.apiUrl + 'admin/entities',
      entity
    );
  }

  public getFieldTypes(): Observable<FieldType[]> {
    return this.get<FieldType[]>(this.apiUrl + 'admin/fieldtypes');
  }

  public newField(field: Field): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl + 'admin/entities/fields',
      field
    );
  }

  public editField(field: Field): Observable<ReturnData<any>> {
    return this.put<ReturnData<any>>(
      this.apiUrl + 'admin/entities/fields',
      field
    );
  }

  public newItem(
    entity: string,
    itemId: string,
    item: any
  ): Observable<ReturnData<Entdata>> {
    return this.post<ReturnData<Entdata>>(
      this.apiUrl + 'entity/item/' + entity + '/' + itemId,
      item
    );
  }

  public bulkSaveItems(
    entity: string,
    bulkItems: BulkItemsBatch
  ): Observable<ReturnData<Entdata[]>> {
    return this.post<ReturnData<Entdata[]>>(
      this.apiUrl + 'entity/bulkitems/' + entity,
      bulkItems
    );
  }

  public copyItem(
    entity: string,
    itemId: string,
    amount: number
  ): Observable<ReturnData<number[]>> {
    return this.get<ReturnData<number[]>>(
      this.apiUrl + 'entity/copyitem/' + entity + '/' + itemId + '/' + amount
    );
  }

  public deactivateItem(itemId: string): Observable<ReturnData<string>> {
    return this.get<ReturnData<string>>(
      this.apiUrl + 'entity/deactivate/item/' + itemId
    );
  }

  public activateItem(itemId: string): Observable<ReturnData<string>> {
    return this.get<ReturnData<string>>(
      this.apiUrl + 'entity/activate/item/' + itemId
    );
  }

  public bulk_deactivateItems(items: string[]): Observable<ReturnData<string>> {
    return this.post<ReturnData<string>>(
      this.apiUrl + 'entity/deactivate/items',
      items
    );
  }

  public bulk_activateItems(items: string[]): Observable<ReturnData<string>> {
    return this.post<ReturnData<string>>(
      this.apiUrl + 'entity/activate/items',
      items
    );
  }

  public reOrderField(
    entityCode: string,
    field1: any,
    field2: any
  ): Observable<ReturnData<any>> {
    let obj = {
      entityCode: entityCode,
      field1: field1.id,
      field2: field2.id,
    };
    return this.post<ReturnData<any>>(
      this.apiUrl + 'admin/entities/flipfields',
      obj
    );
  }
  public massReOrderField(flds: any[]): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl + 'admin/entities/reorderfields',
      flds
    );
  }

  public getHistoryFiles(
    itemId: string,
    field: any
  ): Observable<DocumentFile[]> {
    return this.get<DocumentFile[]>(
      this.apiUrl + 'entity/item/' + itemId + '/' + field.id + '/documents'
    );
  }

  public getPosts(itemId: string): Observable<Post[]> {
    return this.get<Post[]>(this.apiUrl + 'entity/post/' + itemId);
  }

  public sendPost(post: Post): Observable<ReturnData<Post[]>> {
    return this.post<ReturnData<Post[]>>(this.apiUrl + 'entity/post', post);
  }

  public changeEntityStatus(entiy: Entity): Observable<ReturnData<Entity[]>> {
    return this.post<ReturnData<Entity[]>>(
      this.apiUrl + 'entity/status',
      entiy
    );
  }

  public updateEntity(entity: Entity): Observable<ReturnData<Entity[]>> {
    return this.put<ReturnData<Entity[]>>(
      this.apiUrl + 'admin/entities',
      entity
    );
  }

  public addEntConnection(
    connection: Entconnection
  ): Observable<ReturnData<any[]>> {
    return this.post<ReturnData<any[]>>(
      this.apiUrl + 'entity/connections',
      connection
    );
  }

  public removeEntConnection(
    connection: Entconnection
  ): Observable<ReturnData<any[]>> {
    return this.post<ReturnData<any[]>>(
      this.apiUrl + 'entity/connections/delete',
      connection
    );
  }

  public getEntConnection(id: string): Observable<Entity[]> {
    return this.get<Entity[]>(this.apiUrl + 'entity/connections/' + id);
  }

  public getTeamMembers(itemId: number, field: any): Observable<User[]> {
    return this.get<User[]>(
      this.apiUrl + 'entity/item/' + itemId + '/' + field.id + '/team'
    );
  }

  public followItem(itemId: string): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(this.apiUrl + 'entity/follow/' + itemId);
  }
  public unfollowItem(itemId: string): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(this.apiUrl + 'entity/unfollow/' + itemId);
  }

  public getSavedFilters(entityCode: string): Signal<SavedFilter[]> {
    return toSignal(
      this.get<SavedFilter[]>(
        this.apiUrl + 'entity/savedfilters/' + entityCode
      ),
      { injector: this.injector, initialValue: [] }
    );
  }

  public delSavedFilters(savedFilterId: number): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'entity/savedfilters/' + savedFilterId
    );
  }

  public addSavedFilters(
    savedFilter: SavedFilter
  ): Observable<ReturnData<SavedFilter>> {
    return this.post<ReturnData<SavedFilter>>(
      this.apiUrl + 'entity/savedfilters',
      savedFilter
    );
  }

  public globalSearch(
    filters: EntityItemFilter
  ): Observable<SearchEntResults[]> {
    return this.post<SearchEntResults[]>(
      this.apiUrl + 'entity/search',
      filters
    );
  }

  public exportItemToPdf(items: number[], entfield_id): Observable<any> {
    return this.getWithPostFile<any>(
      this.apiUrl + 'entity/pdf/' + entfield_id,
      items
    );
  }

  public getFieldView(field: number, itemId: string): Observable<any> {
    return this.getView<any>(
      this.apiUrl + 'entity/item/' + itemId + '/' + field + '/view'
    );
  }

  public getItemEntity(itemId: string): Observable<Entity> {
    return this.get<Entity>(this.apiUrl + 'entity/belongs/' + itemId);
  }

  public saveColumns(savedColumns: SavedColumns): Observable<any> {
    return this.post<any>(this.apiUrl + 'entity/savedcolumns', savedColumns);
  }

  public deleteView(viewId: number): Observable<any> {
    return this.delete<any>(this.apiUrl + 'entity/savedcolumns/' + viewId);
  }

  public getSavedColumns(entity_code: string): Observable<any> {
    return this.get<any>(this.apiUrl + 'entity/savedcolumns/' + entity_code);
  }

  storeEntities(res: Entity[]) {
    this.storedEntities$.next(res);
    const jwtToken = localStorage.getItem('token');
    const scopesArray = this.helper.decodeToken(jwtToken).scopes;
    const userEntities = res.filter((entity) =>
      scopesArray.includes(entity.entity_code)
    );
    this.storedUserEntities$.next(userEntities);
    const customEntities = res.filter(
      (entity) => entity.isCore === 0 && entity.isActive === 1
    );
    this.storedActiveCustomEntities$.next(customEntities);
  }

  exportGridToExcel(
    filters: BaseFilter,
    entity: string,
    gridColumns: string[]
  ) {
    return this.getWithPostFile(this.apiUrl + 'entity/grid-export/' + entity, {
      ...filters,
      gridColumns,
    });
  }

  public exportProdlistToExcel(
    entdata_id: number,
    prodlines: string[],
    gridColumns: string[]
  ) {
    return this.getWithPostFile(
      this.apiUrl + 'entity/prodlist-export/' + entdata_id,
      {
        prodlines,
        gridColumns,
      }
    );
  }
}
