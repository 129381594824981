import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { EntityItemFilter } from '../../../../filters';
import { take } from 'rxjs/operators';
import { EntityService } from '../../../../../services/entity.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from '../../../../entity-selector-diag/entity-selector-diag.component';
import { Entity, Field } from '../../../../../model';
import { LoginService } from '../../../../../services/login.service';
import { UserService } from '../../../../../services/user.service';
import { FilterService } from '../../../../../services/filter.service';
import { MonthFilterComponent } from '../month-filter/month-filter.component';
import { WeekFilterComponent } from '../week-filter/week-filter.component';

@Component({
  selector: 'app-entity-field-filters',
  standalone: false,
  templateUrl: './entity-field-filters.component.html',
  styleUrl: './entity-field-filters.component.css',
})
export class EntityFieldFiltersComponent implements OnInit {
  @Input() FILTERS: EntityItemFilter;
  @Input() ENTITY: Entity;
  @Input() prefs: Field[];
  @Input() monthFilters: QueryList<MonthFilterComponent>;
  @Input() weekFilters: QueryList<WeekFilterComponent>;
  @Output() modifyPrefs = new EventEmitter<Field[]>();

  private readonly entityService = inject(EntityService);
  private readonly loginService = inject(LoginService);
  private readonly userService = inject(UserService);
  public dialog = inject(MatDialog);
  public filterService = inject(FilterService);

  public entities: any[] = [];
  selectedConnectEntity: Entity;
  fields: any[] = [];

  ngOnInit() {
    this.getEntityList();
    this.fields = this.ENTITY.fields.filter((x) => x.hiddeOnFilters == 0);
  }

  private getEntityList() {
    this.entityService
      .getEntityList()
      .pipe(take(1))
      .subscribe((res) => {
        this.entities = res;
      });
  }

  public selectEntityIDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.selectedConnectEntity.entity_code,
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.FILTERS.connectedWith = result.id;
      }
    });
  }

  public clearConnectedWith() {
    this.FILTERS.connectedWith = null;
  }

  public getEntityNameTranslate(entity: Entity): string {
    let lang = this.loginService.getLoginUser().lang;
    return lang == 'en' ? entity.name_en : entity.name_gr;
  }

  showSearchField(fld_id: number) {
    return this.prefs.some((x) => x.id === fld_id);
  }

  modifyFilterPreferences(field: Field) {
    this.prefs = this.userService.modifyFilterPreferences(this.prefs, field);
    this.modifyPrefs.emit(this.prefs);
  }

  checkForCreationDateRangeFilter(field: Field) {
    if (field.fld_code == 'task_startdate') {
      this.filterService.creationDateRangeFilter.set('');
    }
  }

  toggleCreationDateRangeFilter() {
    this.filterService.creationDateRangeFilterOnBar.update((value) => !value);
    if (this.filterService.creationDateRangeFilterOnBar()) {
      localStorage.setItem('taskCreationDateRangeFilterOnBar', 'true');
    } else {
      localStorage.removeItem('taskCreationDateRangeFilterOnBar');
    }
  }

  public autoFillFrom(field: Field) {
    this.filterService.autoFillFrom(field);
  }

  public clearSearchValues(field: Field) {
    this.filterService.clearSearchValues(
      field,
      this.monthFilters,
      this.weekFilters
    );
  }

  applyCreationDateRangeFilter() {
    this.filterService.applyCreationDateRangeFilter(this.ENTITY);
  }

  clearCreationDateRangeFilter() {
    this.filterService.clearCreationDateRangeFilter(this.ENTITY);
  }
}
