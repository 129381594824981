import { Component, Injectable, Input, OnInit } from '@angular/core';
import {
  MatDatepickerModule,
  MatDateRangeInput,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { startOfWeek, addDays, startOfYear, addWeeks } from 'date-fns';
import { DateAdapter } from 'angular-calendar';


@Injectable()
export class FiveDayRangeSelectionStrategy
  implements MatDateRangeSelectionStrategy<string> {
  constructor(private _dateAdapter: DateAdapter) { }

  selectionFinished(date: string | null): DateRange<string> {
    return this._createFiveDayRange(date);
  }

  createPreview(activeDate: string | null): DateRange<string> {
    return this._createFiveDayRange(activeDate);
  }

  private _createFiveDayRange(date: string | null): DateRange<any> {
    if (date) {
      const d = new Date(date);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1);
      const start = new Date(d.setDate(diff));
      const end = new Date(d.getTime() + 6 * 24 * 60 * 60 * 1000);
      return new DateRange<any>(start, end);
    }

    return new DateRange<string>(null, null);
  }
}

@Component({
  selector: 'app-week-filter',
  standalone: true,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: FiveDayRangeSelectionStrategy,
    },
    DatePipe,
  ],
  imports: [
    MatDatepickerModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './week-filter.component.html',
  styleUrl: './week-filter.component.css',
})
export class WeekFilterComponent implements OnInit {
  @Input() ITEM!: any;
  @Input() disabled = false;
  @Input() title = 'Εβδομάδα';

  startDate: Date | null = null;
  endDate: Date | null = null;

  private readonly datePipe = new DatePipe('en-US');
  
   date = new FormControl();

  ngOnInit() {
      if (this.ITEM.value !== null) {
        const yearMonth = this.ITEM.value.split('-W').map((x) => Number(x));
        const yearStart = startOfYear(new Date(yearMonth[0], 0, 1, 0, 0, 0));
        const startOfWeek = addWeeks(yearStart, yearMonth[1] - 1);
        this.startDate = startOfWeek;
        this.endDate = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
    }
  
    convertDateToWeek($event: any) {
      const selectedDate = new Date($event.value);
      this.ITEM.search_from = this.getWeekValueFromDate(selectedDate);
    }

  
    getWeekValueFromDate(date: Date) {
      const year = date.getFullYear();
      const isoWeekNumber = this.getISOWeekNumber(date);
      return `${year}-W${isoWeekNumber.toString().padStart(2, '0')}`;
    }
  
    getISOWeekNumber(date: Date) {
      const target = new Date(date.valueOf());
      const dayNumber = (date.getUTCDay() + 6) % 7;
      target.setUTCDate(target.getUTCDate() - dayNumber + 3); // Thursday of the current week
      const firstThursday = new Date(target.getUTCFullYear(), 0, 4); // January 4th
      return (
        1 + Math.ceil((+target - +firstThursday) / (7 * 24 * 60 * 60 * 1000))
      );
    }

  clearValue() {
    this.date.setValue(null);
  }

  setValue(value: string) {
    console.log('Setting Week Filter Value:', value); // Debugging log
    this.date.setValue(value);
    this.ITEM.search_from = value;
  }

}
