<form class="example-form">
  <mat-form-field class="example-full-width">
    <input
      type="text"
      aria-label="Number"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onProductBatchSelected($event)"
      [displayWith]="displayFn"
    >
      @for (batch of filteredOptions | async; track batch.spdoc_id) {
        <mat-option [value]="batch"
          >{{ batch.code }} ({{ batch.qty1 }})</mat-option
        >
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
