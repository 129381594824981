import { TranslateService } from '@ngx-translate/core';
import { EntityService } from '../../../services/entity.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import {
  Entconnection,
  Entdata,
  User,
  ItemTab,
  AdditionalBtn,
} from '../../../model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from '../../entity-selector-diag/entity-selector-diag.component';
import { LoginService } from 'src/app/services/login.service';
import { Location } from '@angular/common';
import { DataReplicateComponent } from './data-replicate/data-replicate.component';
import { AfterSaveEnityItemService } from 'src/app/services/after-save-enity-item.service';
import { DataConnectedListComponent } from './data-connected-list/data-connected-list.component';
import { DataQuestionComponent } from '../data-question/data-question.component';
import { TransformEntityToErpComponent } from '../../erp/transformation/transform-entity-to-erp/transform-entity-to-erp.component';
import { TransformationHelper } from '../../helper';
import { WebsocketsService } from '../../../services/websockets.service';
import { ElementDataService } from 'src/app/services/elementdata.service';
import { format } from 'date-fns';
import { LayoutService } from '../../../services/layout.service';
import { WorkflowService } from '../../../services/workflow.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.css'],
})
export class DataItemComponent implements OnInit {
  @Input() ITEMIDCOMP: any;
  @Input() ENTITYCOMP: string;
  @Input() SHOWTOOLBAR: boolean = true;
  @Input() SHOWHBACKBTN: boolean = true;
  @Input() ITEMSOURCECOMP: string;
  @Input() ADDBUTTONS: AdditionalBtn[];
  @Input() isDialog = false;
  @Output() SAVE = new EventEmitter<any>();
  @Output() BACK = new EventEmitter<boolean>();
  @Output() BACKWORKFLOW = new EventEmitter<number>();
  @Output() ADDITIONALBTNACTION = new EventEmitter<AdditionalBtn>();

  @ViewChild(DataConnectedListComponent)
  connectedListComp: DataConnectedListComponent;

  public isRoutedPage: boolean;
  public currentUser: User;
  public showMandatoryErrors: boolean = false;
  public showInvalidEndDate: boolean = false;
  public hasChanges: boolean = false;
  public canTransformToErp: boolean = false;
  private currentIndex: number = 0;

  constructor(
    private entityService: EntityService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private afterSaveEntityItemService: AfterSaveEnityItemService,
    public dialog: MatDialog,
    private loginService: LoginService,
    private __location: Location,
    public translateService: TranslateService,
    private websocketService: WebsocketsService,
    private ElementDataService: ElementDataService,
    public layoutService: LayoutService,
    private workflowService: WorkflowService
  ) {}

  currentEntItem: Entdata;
  itemIdToOpen: string;
  entityToOpen: string;
  tabs: ItemTab[] = [];
  basicFields: any = { fields: [], steps: [] };
  tabFields: any[] = [];
  extractedData: any[] = [];
  showSaveButton = false;

  ngOnInit(): void {
    this.currentUser = this.loginService.getLoginUser();

    if (this.ITEMIDCOMP && this.ENTITYCOMP) {
      this.isRoutedPage = false;
      this.itemIdToOpen = this.ITEMIDCOMP;
      this.entityToOpen = this.ENTITYCOMP;
    } else {
      this.isRoutedPage = true;
      this.itemIdToOpen = this.route.snapshot.paramMap.get('item');
      this.entityToOpen = this.route.snapshot.paramMap.get('entity');
    }

    this.canTransformToErp = TransformationHelper.CanEntityTransformToErpHelper(
      this.entityToOpen
    );

    this.getEntityItem();

    if (this.itemIdToOpen !== 'new') {
      this.extractedData = this.ElementDataService.getExtractedData();
      this.currentIndex = this.extractedData?.findIndex(
        (item) => item.id === +this.itemIdToOpen
      );
    }
    this.tabFields = this.tabFields.filter((tab) => this.shouldShowTab(tab));
  }

  private getEntityItem() {
    this.entityService
      .geEntityItem(this.entityToOpen, this.itemIdToOpen)
      .pipe(take(1))
      .subscribe((resItem) => {
        this.currentEntItem = null;
        resItem.data = this.normalizeChekbox(resItem.data);
        this.currentEntItem = resItem;

        this.getTabs();
        this.modifyFields();
        this.checkForSaveButton();
      });
  }

  public normalizeChekbox(data: any[]): any[] {
    for (let attr of data) {
      if (attr.fld_code == 'checkbox') {
        attr.value = (attr.value == 'true' || attr.value == '1') + '';
      }
    }

    return data;
  }

  public save() {
    if (this.checkForMantadoryErrors()) {
      this.notificationService.showSnackbarMessage('Messages.hasMandatoryFlds');
      return;
    }
    if (this.checkForInvalidEndDate()) {
      this.notificationService.showSnackbarMessage(
        'Messages.hasInvalidEndDate'
      );
      this.showInvalidEndDate = true;
      return;
    }
    this.hasChanges = true;
    this.currentEntItem.data = this.normalizeChekbox(this.currentEntItem.data);

    this.entityService
      .newItem(this.entityToOpen, this.itemIdToOpen, this.currentEntItem)
      .pipe(take(1))
      .subscribe((resItem) => {
        if (resItem.success) {
          if (this.itemIdToOpen == 'new' && this.ITEMSOURCECOMP) {
            this.currentEntItem = resItem.data;

            this.newConnection(+this.ITEMSOURCECOMP);
          } else {
            this.currentEntItem = resItem.data;
          }

          this.currentEntItem.data = this.normalizeChekbox(
            this.currentEntItem.data
          );

          this.getTabs();
          this.modifyFields();
          if (this.entityToOpen == 'prodrecipe' && this.itemIdToOpen != 'new') {
            this.connectedListComp.getEntCodeConnections();
          }

          this.itemIdToOpen = resItem.data.id + '';
          this.SAVE.emit(this.currentEntItem);
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );

          this.afterSaveEntityItemService.specialLogicAfterSave(
            this.entityToOpen,
            this.currentEntItem
          );
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public copy() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DataReplicateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: number) => {
      if (result) {
        this.multiCopy(result);
      }
    });
  }

  public multiCopy(amount: number) {
    this.hasChanges = true;
    this.currentEntItem.data = this.normalizeChekbox(this.currentEntItem.data);

    this.entityService
      .copyItem(this.entityToOpen, this.itemIdToOpen, amount)
      .pipe(take(1))
      .subscribe((resItem) => {
        if (resItem.success) {
          this.SAVE.emit(this.currentEntItem);
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public deactivate() {
    this.entityService
      .deactivateItem(this.itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successDeactivate',
            'Ok',
            { duration: 3000 }
          );
          this.getEntityItem();
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.failDeactivate',
            'Ok',
            { duration: 3000 }
          );
        }
      });
  }

  public activate() {
    this.entityService
      .activateItem(this.itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successΑctivate',
            'Ok',
            { duration: 3000 }
          );
          this.getEntityItem();
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.failΑctivate',
            'Ok',
            { duration: 3000 }
          );
        }
      });
  }

  private newConnection(withId: number) {
    let newConnection: Entconnection = new Entconnection();
    newConnection.entdata_id = +this.currentEntItem.id;
    newConnection.with_entdata_ids = [withId];
    this.entityService
      .addEntConnection(newConnection)
      .pipe(take(1))
      .subscribe((resCon) => {
        if (resCon.success) {
          this.connectedListComp.getEntCodeConnections();
        }
      });
  }

  public goBack() {
    if (this.isRoutedPage) {
      this.__location.back();
    } else {
      if (this.workflowService.createNewRecordForWorkflow) {
        this.BACKWORKFLOW.emit(this.currentEntItem.id);
        this.workflowService.createNewRecordForWorkflow = false;
      } else {
        this.BACK.emit(this.hasChanges);
      }
    }
  }

  public isDisabled(fld: any): boolean {
    let flag: boolean;

    flag = this.currentEntItem && this.currentEntItem.isActive == 0;
    return flag;
  }

  public selectOwnerDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.currentEntItem.owner = result;
        this.currentEntItem.owner_id = result.id;
      }
    });
  }

  public followItem() {
    this.entityService
      .followItem(this.itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.currentEntItem.follow = true;
        }
      });
  }

  public unfollowItem() {
    this.entityService
      .unfollowItem(this.itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.currentEntItem.follow = false;
        }
      });
  }

  private checkForMantadoryErrors(): boolean {
    this.showMandatoryErrors = true;
    for (let attr of this.currentEntItem.data) {
      if (attr.isMandatory == '1' && (attr.value == '' || !attr.value)) {
        return true;
      }
    }
    return false;
  }

  private checkForInvalidEndDate(): boolean {
    if (this.entityToOpen === 'absence') {
      let absence_end_date = this.currentEntItem.data.filter(
        (x) => x.fld_code_ent === 'absence_end_absence'
      )[0].value;
      if (absence_end_date === null) {
        this.showInvalidEndDate = false;
        return false;
      } else {
        let absence_start_date = this.currentEntItem.data.filter(
          (x) => x.fld_code_ent === 'absence_start_absence'
        )[0].value;
        if (
          this.formatDate(absence_start_date) >=
          this.formatDate(absence_end_date)
        ) {
          this.showInvalidEndDate = true;
          return true;
        }
      }
    }
    this.showInvalidEndDate = false;
    return false;
  }

  public getTabs() {
    let tmpSteps: ItemTab[] = [];

    let newTab: ItemTab = new ItemTab();
    newTab.endIndex = this.currentEntItem.data.length - 1;
    for (let i = this.currentEntItem.data.length - 1; i > 0; i--) {
      let fld = this.currentEntItem.data[i];
      if (fld.fld_code == 'tab') {
        newTab.title = this.getFldTranslation(fld);
        newTab.startIndex = i;
        newTab.tabFld = fld;

        tmpSteps.push(newTab);
        newTab = new ItemTab();

        newTab.endIndex = i - 1;
      }
    }
    this.tabs = tmpSteps.reverse();
  }

  public getFldTranslation(fld: any): string {
    return this.loginService.getLoginUser().lang == 'en'
      ? fld.label_en
      : fld.label_gr;
  }

  public add_question_diag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.entityToOpen,
      itemId: this.itemIdToOpen,
    };

    const dialogRef = this.dialog.open(DataQuestionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  public sendAdditionalAction(addBtn: AdditionalBtn) {
    addBtn.itemAction = this.itemIdToOpen;
    this.ADDITIONALBTNACTION.emit(addBtn);
  }

  public formatDate(date: string) {
    var s = date.split(' ')[0]?.split('-');
    var m = date.includes('T')
      ? date?.split('T')[1]?.split(':')
      : date?.split(' ')[1]?.split(':');
    //console.log("format date:"+new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0));
    return new Date(
      parseInt(s[0]),
      parseInt(s[1]) - 1,
      parseInt(s[2]),
      parseInt(m[0]),
      parseInt(m[1]),
      0,
      0
    );
  }

  modifyFields() {
    this.basicFields = { fields: [], steps: [] };
    this.tabFields = [];

    let insideTab = false;
    let insideStep = false;
    let fieldsWithoutHidden = this.currentEntItem.data.filter(
      (item) => item.fld_code !== 'hidden'
    );
    fieldsWithoutHidden.forEach((item) => {
      item.fields = [];
      item.steps = [];
      if (item.fld_code === 'tab') {
        insideTab = true;
        insideStep = false;
        item.title = this.getFldTranslation(item);
        this.tabFields.push(item);
      } else if (item.fld_code === 'step') {
        insideStep = true;
        item.title = this.getFldTranslation(item);
        if (insideTab) {
          this.tabFields[this.tabFields.length - 1].steps.push(item);
        } else {
          this.basicFields.steps.push(item);
        }
      } else {
        if (!insideTab && !insideStep) {
          this.basicFields.fields.push(item);
        } else if (!insideTab && insideStep) {
          this.basicFields.steps[this.basicFields.steps.length - 1].steps.push(
            item
          );
        } else if (insideTab && !insideStep) {
          this.tabFields[this.tabFields.length - 1].fields.push(item);
        } else {
          let stepsLength =
            this.tabFields[this.tabFields.length - 1].steps.length;
          this.tabFields[this.tabFields.length - 1].steps[
            stepsLength - 1
          ].fields.push(item);
        }
      }
    });

    if (
      this.currentEntItem.data.filter((item) => item.fld_code === 'step')
        .length != 0
    ) {
      this.getActiveSteps(this.basicFields);
      this.tabFields.forEach((tab) => {
        this.getActiveSteps(tab);
      });
    }
  }

  public unlockPrevStep(tabIndex: number) {
    this.tabFields[tabIndex].steps[
      this.tabFields[tabIndex].activeStep - 1
    ].value = null;
    this.tabFields[tabIndex].activeStep--;
  }

  public completeStep(step: any) {
    if (!this.checkForMantadoryErrorsInsideStep(step)) {
      const timestamp = format(new Date(), 'hh:mm dd/MM/yyyy');
      step.value = `${step.value} | Completed|${timestamp}`;
    }
  }

  public startStep(step: any) {
    const timestamp = format(new Date(), 'hh:mm dd/MM/yyyy');
    step.value = `Started|${timestamp}`;
  }

  private checkForMantadoryErrorsInsideStep(step: any): boolean {
    this.showMandatoryErrors = true;
    let error = false;
    step.fields.forEach((field) => {
      if (field.isMandatory == '1' && (field.value == '' || !field.value)) {
        error = true;
        return true;
      }
    });
    return error;
  }

  private getActiveSteps(object: any) {
    if (object.steps.length != 0) {
      let index = 0;
      let stop = false;
      do {
        if (object.steps[index].value === null) {
          stop = true;
          object.activeStep = index;
        }
        index++;
      } while (!stop && index < object.steps.length);
      if (!stop) {
        object.activeStep = index - 1;
      }
    }
  }

  updateActiveStep(tab: any) {
    tab.activeStep++;
  }

  getDate(stepValue: string, type: 'Started' | 'Completed'): string {
    const segments = stepValue.split('|');
    if (type === 'Started' && segments[0].includes('Started')) {
      return segments[1].trim();
    } else if (type === 'Completed' && segments.length > 2 && segments[2].includes('Completed')) {
      return segments[3].trim();
    }
    return '';
  }

  public transform_to_Erp() {
    const dialogConfig = new MatDialogConfig();
    const backorderValue = (this.currentEntItem?.fields || []).find(
      (field) => field.field.fld_code === 'productionorder_backorder'
    )?.value;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    // Open a selection dialog
    const dialogRef = this.dialog.open(TransformEntityToErpComponent, {
      disableClose: true,
      autoFocus: true,
      width: '400px',
      data: {
        backorderValue,
        entity_ids: [+this.itemIdToOpen],
        selection: true, // Indicates this is a selection dialog
      },
    });

    dialogRef.afterClosed().subscribe((selection: 'erp' | 'pickingorder' | 'receivinglist' | 'inventory' |null) => {
      if (selection === 'erp') {
        this.openTransformationDialog('erp', backorderValue);
      } else if (selection === 'pickingorder') {
        this.openTransformationDialog('pickingorder', backorderValue);
      } else if (selection === 'receivinglist') {
        this.openTransformationDialog('receivinglist', backorderValue);
      } else if (selection === 'inventory') {
        this.openTransformationDialog('receivinglist', backorderValue);
      }
    });
  }

  private openTransformationDialog(type: 'erp' | 'pickingorder' | 'receivinglist' | 'inventory', backorderValue: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      backorderValue,
      entity_ids: [+this.itemIdToOpen],
      transformationType: type, // Passes the selected transformation type
    };

    const dialogRef = this.dialog.open(TransformEntityToErpComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        // Optionally handle dialog success
        console.log(`Transformation to ${type} completed.`);
      }
    });
  }


  checkForSaveButton() {
    if (this.itemIdToOpen === 'new') {
      this.showSaveButton = true;
    } else {
      if (this.currentEntItem !== undefined && this.currentEntItem !== null) {
        this.showSaveButton = this.currentEntItem.isActive == 1;
      } else {
        this.showSaveButton = true;
      }
    }
  }

  checkForQuestions(tabFields: any) {
    let matchId = -1;
    for (const element of tabFields) {
      matchId = this.websocketService.questions.findIndex(
        (question) =>
          question.entfield_id == element.id &&
          question.entdata_id == this.ITEMIDCOMP
      );
      if (matchId !== -1) {
        break;
      }
    }
    return matchId !== -1;
  }

  private getNextItemId(): number | undefined {
    const nextIndex = this.currentIndex + 1;
    if (nextIndex < this.extractedData.length) {
      return this.extractedData[nextIndex].id;
    }
    return undefined;
  }

  private getPreviousItemId(): number | undefined {
    const previousIndex = this.currentIndex - 1;
    if (previousIndex >= 0) {
      return this.extractedData[previousIndex].id;
    }
    return undefined;
  }

  public goToNext() {
    const nextItemId = this.getNextItemId();
    if (nextItemId !== undefined) {
      this.itemIdToOpen = nextItemId.toString();
      this.currentIndex++;
      this.getEntityItem();
    } else {
      this.notificationService.showSnackbarMessage('Messages.lastindexitem');
    }
  }

  public goToPrevious() {
    const previousItemId = this.getPreviousItemId();
    if (previousItemId !== undefined) {
      this.itemIdToOpen = previousItemId.toString();
      this.currentIndex--;
      this.getEntityItem();
    } else {
      this.notificationService.showSnackbarMessage('Messages.firstindexitem');
    }
  }

  //CUSTOM METHOD FOR GLM TO SHOW OR HIDE TABS IN PRODUCTION ORDERS
  shouldShowTab(tab: any): boolean {
    const phaseList = this.currentEntItem.data.find(
      (attr) => attr.fld_code_ent === 'productionorder_phase_list'
    );

    if (this.entityToOpen === 'productionorder' && phaseList) {
      const phaseListValue = phaseList.value.trim();
      const showPhase0 = phaseListValue === 'Φάση 0';
      const showPhase1 = phaseListValue === 'Φάση 1';
      const showPhase2 = phaseListValue === 'Φάση 2';
      const showPhase3 = phaseListValue === 'Φάση 3';

      if (tab.fld_code_ent === 'productionorder_phase0_tab') {
        return showPhase0;
      } else if (tab.fld_code_ent === 'productionorder_phase1_tab') {
        return showPhase1;
      } else if (tab.fld_code_ent === 'productionorder_phase2_tab') {
        return showPhase2;
      } else if (tab.fld_code_ent === 'productionorder_phase3_tab') {
        return showPhase3;
      }
    }

    return true;
  }
}
