<div class="GridDiv">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-form-field class="full-width">
    <mat-label>{{ 'QuoteConfigurator.totalDiscount' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="confQuote.totalDiscount"
      type="number"
      (ngModelChange)="changeTotalDisc()"
      [disabled]="disabled"
    />
    <mat-icon matSuffix>percent</mat-icon>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>{{ 'ERPWarehouse.warehouse' | translate }}</mat-label>
    <mat-select
      [(ngModel)]="selectedWarehouse"
      (ngModelChange)="changeWarehouse()"
      [disabled]="disabled || warehouses.length === 0"
      [panelWidth]="null"
    >
      <mat-option *ngFor="let warehouse of warehouses" [value]="+warehouse?.id">
        {{ warehouse.id }} - {{ warehouse.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-table
    #productTable
    [dataSource]="confQuote.quotelines"
    [class.prodTable-M]="layoutService.isHandset$ | async"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal"
    class="product-list-table"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef cdkDrag> # </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">{{ element.aa }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="product_name">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.prodName' | translate }}</mat-header-cell
      >

      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          #{{ element.product.id }} {{ element.product.product_name }}
        </span>

        <span *ngIf="!element.product">
          <app-entity-selector-autocomplete
            ENTITY="product"
            [fieldCode]="ITEM.fld_code_ent"
            (selectEntityItem)="fillProdlineItem($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qty">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.qty' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.qty"
            (ngModelChange)="refresh()"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unit">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.unit' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <select
            [(ngModel)]="element.unit"
            matNativeControl
            required
            (ngModelChange)="ChangeUnit(element)"
            [disabled]="disabled || !element.product"
          >
            <option [value]="0">
              {{ 'QuoteConfigurator.piece' | translate }}
            </option>
            <option [value]="1">
              {{ 'QuoteConfigurator.smallBox' | translate }}
            </option>
            <option [value]="2">
              {{ 'QuoteConfigurator.box' | translate }}
            </option>
            <option [value]="3">
              {{ 'QuoteConfigurator.palet' | translate }}
            </option>
            <option [value]="4">
              {{ 'QuoteConfigurator.metre' | translate }}
            </option>
            <option [value]="5">
              {{ 'QuoteConfigurator.kilo' | translate }}
            </option>
          </select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="availability">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.availability' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <select
            [(ngModel)]="element.availability"
            matNativeControl
            required
            (ngModelChange)="refresh()"
            [disabled]="disabled || !element.product"
          >
            <option [value]="1">
              {{ 'QuoteConfigurator.available' | translate }}
            </option>
            <option [value]="2">
              {{ 'QuoteConfigurator.limit' | translate }}
            </option>
            <option [value]="3">
              {{ 'QuoteConfigurator.notAvailable' | translate }}
            </option>
            <option [value]="4">
              {{ 'QuoteConfigurator.afterOrder' | translate }}
            </option>
          </select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vat">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.vat' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <select
            [(ngModel)]="element.vat"
            matNativeControl
            required
            (ngModelChange)="refresh()"
            [disabled]="disabled || !element.product"
          >
            <option [value]="24">24%</option>
            <option [value]="17">17%</option>
            <option [value]="13">13%</option>
            <option [value]="9">9%</option>
            <option [value]="6">6%</option>
            <option [value]="4">4%</option>
            <option [value]="0">0%</option>
          </select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="discount">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.discount' | translate }} %
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.discount"
            (ngModelChange)="refresh()"
            type="number"
            numberFormat
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="discount2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.discount' | translate }} 2 %</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.discount2"
            (ngModelChange)="refresh()"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qr-scanner-search">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.qrScannerSearch' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="primary"
          (click)="openQRScanner(element, 'search')"
          [disabled]="disabled"
          *ngIf="!element.product"
        >
          <mat-icon>camera</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qr-scanner-validator">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.qrScannerValidator' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="primary"
          (click)="openQRScanner(element, 'validate')"
          [disabled]="disabled"
          *ngIf="element.product"
        >
          <mat-icon>camera</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="productBatch">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.productBatch' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        @if (
          ITEM?.fld_code_ent?.startsWith('pickingorder') && element.product
        ) {
          <app-product-batch-autocomplete
            [productId]="element.product_id"
            [quoteLine]="element"
          >
          </app-product-batch-autocomplete>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="del">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="warn"
          (click)="removeItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.product"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="order">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.orderLine' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="primary"
          (click)="upItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.product && i != 0"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="downItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.product && i != confQuote.quotelines.length - 2"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ProdConfigurator.price' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.price"
            (ngModelChange)="refresh()"
            type="number"
            [disabled]="disabled || !element.product || disableCustomPrice"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="backorder">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ProdConfigurator.backorder' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <span>{{ element.backorder }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="piecePrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.piecePrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{ +element.product.product_price_per_piece }}</span
        >
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="smallBoxPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.smallBoxPrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{ +element.product.product_price_per_small_box }}</span
        >
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="boxPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.boxPrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">{{
          +element.product.product_price_per_box
        }}</span>
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paletPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.paletPrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><span *ngIf="element.product"
          >{{ +element.product.product_price_per_palet }}
        </span>
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nettoPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'ProdConfigurator.NettoPrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><span *ngIf="element.product"
          >{{ +element.product.product_nettoprice }}
        </span>
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="stock">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.stock' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{
            element.product.product_stock ? element.product.product_stock : 0
          }}</span
        >
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalPieces">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.totalPieces' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product"
          >{{ getTotalPieces(element) | numberFormat }}
        </span>
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <mat-header-cell *matHeaderCellDef cdkDrag
        >{{ 'QuoteConfigurator.totalPrice' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{ getTotalPrice(element) | numberFormat }}
        </span>
        <span *ngIf="!element.product">0</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="warehouse">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ERPWarehouse.warehouse' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <app-warehouse-autocomplete
          [element]="element"
          [warehouses]="warehouses"
        >
        </app-warehouse-autocomplete>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="containers">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.containers' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <input
          matInput
          [(ngModel)]="element.containers"
          type="number"
          [disabled]="
            !element.product ||
            !element.product.product_box_available ||
            element.product.product_box_available != 1
          "
          min="0"
          step="1"
          (ngModelChange)="calculateQuantity(element)"
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfNumFld1')[0]"
          >{{ settingsService.getSavedSettings('quoteConfNumFld1')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfNumFld1')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}1
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld1"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfNumFld2')[0]"
          >{{ settingsService.getSavedSettings('quoteConfNumFld2')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfNumFld2')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}2
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld2"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfNumFld3')[0]"
          >{{ settingsService.getSavedSettings('quoteConfNumFld3')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfNumFld3')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}3
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld3"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfStrFld1')[0]"
          >{{ settingsService.getSavedSettings('quoteConfStrFld1')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfStrFld1')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}4
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld1"
            type="text"
            [disabled]="disabled || !element.product"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfStrFld2')[0]"
          >{{ settingsService.getSavedSettings('quoteConfStrFld2')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfStrFld2')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}5</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld2"
            type="text"
            [disabled]="disabled || !element.product"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('quoteConfStrFld3')[0]"
          >{{ settingsService.getSavedSettings('quoteConfStrFld3')[0]?.value }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('quoteConfStrFld3')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}6</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld3"
            type="text"
            [disabled]="disabled || !element.product"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]"
          >{{
            settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}7</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld1"
            class="full-width"
            [disabled]="disabled || !element.product"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'quoteConfDrpDownValues1'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]"
          >{{
            settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}8</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld2"
            class="full-width"
            [disabled]="disabled || !element.product"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'quoteConfDrpDownValues2'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]"
          >{{
            settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}9</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld3"
            class="full-width"
            [disabled]="disabled || !element.product"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'quoteConfDrpDownValues3'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container
      [matColumnDef]="prodFld.fld_code"
      *ngFor="let prodFld of product_fields"
    >
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ getProdFldHeader(prodFld) }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{ element.product[prodFld.fld_code] }}
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"> </mat-row>
  </mat-table>

  <section style="display: flex; flex-wrap: wrap">
    <mat-card
      style="margin-top: 10px"
      [ngClass]="{
        cardTotalWidth: !(layoutService.isHandset$ | async),
        'cardTotalWidth-M': (layoutService.isHandset$ | async)
      }"
    >
      {{ 'QuoteConfigurator.total' | translate }}:
      {{ confQuote.totalAmount | numberFormat }}
    </mat-card>
  </section>
</div>

<mat-menu #columns="matMenu">
  <button mat-menu-item (click)="onExportToExcel()">
    {{ 'Generic.export' | translate }}
  </button>
  <mat-selection-list
    [ngModel]="selectedColumns"
    (ngModelChange)="updateSelectedColumns($event)"
    style="max-height: 70vh"
  >
    <mat-list-option
      color="primary"
      *ngFor="let column of gridCols"
      [value]="column.key"
      (click)="$event.stopPropagation()"
    >
      {{ column.desc }}
    </mat-list-option>

    <mat-divider></mat-divider>
    <div mat-subheader>{{ 'QuoteConfigurator.product' | translate }}</div>
    <mat-divider></mat-divider>
    <mat-list-option
      color="primary"
      *ngFor="let prodFld of product_fields"
      [value]="prodFld.fld_code"
      (click)="$event.stopPropagation()"
    >
      {{ getFldTranslation(prodFld) }}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
