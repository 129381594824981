import {
  AfterViewInit,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { PurchasingorderLine } from 'src/app/models/purchasingorder';
import { PurchasingorderService } from 'src/app/services/purchasingorder.service';
import { Field } from 'src/app/model';
import { LoginService } from 'src/app/services/login.service';
import { EntityService } from 'src/app/services/entity.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LayoutService } from '../../../../../services/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-purchasingorderlines-field',
  templateUrl: './purchasingorderlines-field.component.html',
  styleUrls: ['./purchasingorderlines-field.component.css'],
})
export class PurchasingorderlinesFieldComponent
  implements OnInit, AfterViewInit
{
  public lines: PurchasingorderLine[] = [];
  displayedColumns: string[] = [
    'productname',
    'initial_qty',
    'qty',
    'normalized_qty',
    'supplier_name',
    'price',
    'value',
    'backorder',
    'del',
  ];
  selectedColumns: string[] = [];
  localstorageKey: string;
  gridCols: any[] = [];
  product_fields: Field[] = [];
  isHandset: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('supplierSelector') supplierSelector: any;

  constructor(
    private purchasingorderService: PurchasingorderService,
    public loginService: LoginService,
    private entityService: EntityService,
    private translateService: TranslateService,
    public layoutService: LayoutService,
    private destroyRef: DestroyRef
  ) {}

  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @Input() allAttr: any;

  ngOnInit(): void {
    this.getProductFields();
    this.ITEM.prodlines = [];

    this.layoutService.isHandset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        this.isHandset = x;
      });
  }

  ngAfterViewInit(): void {
    if (this.ITEMID != 'new') {
      this.getSavedLines(this.ITEMID);
    } else {
      this.addItemLine(null);
    }
  }

  private getSavedLines(purchasingorderId: string) {
    this.purchasingorderService
      .getSavedLines(purchasingorderId)
      .pipe(take(1))
      .subscribe((res) => {
        this.ITEM.prodlines = res;
        this.lines = res;
        this.addItemLine(null);
        this.refresh();
      });
  }

  public removeItemLine(index: number) {
    this.lines.splice(index, 1);
    this.refresh();
  }

  public fillProdlineItem(item: any, line: PurchasingorderLine) {
    line.product = item;
    line.product_id = item.id;
    line.price = item.product_supplier_price ?? 0;
    this.addItemLine(null);
  }

  public fillProdlineSupplier(supplier: any, line: PurchasingorderLine) {
    line.supplier = supplier;
    line.supplier_id = supplier.id;
  }

  public deleteProdLineSupplier(line: PurchasingorderLine) {
    line.supplier = null;
    line.supplier_id = null;
  }

  public refresh() {
    this.table.renderRows();
    this.ITEM.prodlines = this.lines.filter((x) => x.product);

    const totalValue = this.calculateTotalValue();
    let totalValueField = this.allAttr.find(
      (x) => x.fld_code_ent === 'purchasingorder_total_value'
    );
    if (totalValueField) {
      totalValueField.value = totalValue;
    }
  }

  public addItemLine(item: any) {
    let newLine: PurchasingorderLine = new PurchasingorderLine();
    newLine.product = item;
    newLine.qty = 1;
    newLine.normalized_qty = 1;
    newLine.product_id = item?.id ?? null;
    newLine.price = item?.product_supplier_price ?? 0;
    this.lines.push(newLine);
    this.refresh();
  }

  updateSelectedColumns($event) {
    let difference = this.selectedColumns
      .filter((item) => !$event.includes(item))
      .concat($event.filter((item) => !this.selectedColumns.includes(item)));
    if (this.selectedColumns.includes(difference[0])) {
      this.selectedColumns = this.selectedColumns.filter(
        (item) => item !== difference[0]
      );
    } else {
      this.selectedColumns.push(difference[0]);
    }
    this.storeSettings();
  }

  storeSettings() {
    let delIndex = this.selectedColumns.findIndex((x) => x == 'del');
    if (delIndex > -1) {
      this.selectedColumns.splice(delIndex, 1);
      this.selectedColumns.push('del');
    }
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  public getFldTranslation(fld: Field): string {
    return this.loginService.getLoginUser().lang == 'en'
      ? fld.label_en
      : fld.label_gr;
  }

  private getProductFields() {
    this.entityService
      .getFldsEntityByName('product')
      .pipe(take(1))
      .subscribe((res) => {
        this.product_fields = res.fields.filter(
          (x) =>
            x.isGridColumn == 1 &&
            x.fld_code != 'product_name' &&
            x.fld_code != 'piecePrice' &&
            x.fld_code != 'smallBoxPrice' &&
            x.fld_code != 'boxPrice' &&
            x.fld_code != 'paletPrice' &&
            x.fld_code != 'nettoPrice' &&
            x.fld_code != 'stock'
        );

        this.rememberSelectedCols();
      });
  }

  rememberSelectedCols() {
    this.makeGridCols();

    let additionalLocalStorageKey = this.isHandset ? 'mobile' : 'desktop';
    this.localstorageKey =
      'grid_purchasingorderlines_' + additionalLocalStorageKey;
    let savedColumnsJSON = localStorage.getItem(this.localstorageKey);
    if (savedColumnsJSON) {
      let savedColumns = JSON.parse(savedColumnsJSON).filter(
        (x) =>
          x != 'deactivateBulkAction' &&
          x != 'activateBulkAction' &&
          x != 'editBulkAction'
      );
      this.selectedColumns = [];

      for (let savedColumn of savedColumns) {
        if (
          this.displayedColumns.find((x) => x == savedColumn) ||
          this.product_fields.find((x) => x.fld_code == savedColumn)
        ) {
          this.selectedColumns.push(savedColumn);
        }
      }
    } else {
      this.selectedColumns = [
        'productname',
        'initial_qty',
        'qty',
        'normalized_qty',
        'supplier_name',
        'price',
        'value',
        'backorder',
        'del',
      ];
    }
    this.table.renderRows();
  }

  private makeGridCols() {
    this.gridCols.push(
      {
        key: 'productname',
        desc: this.translateService.instant('PurchasingOrder.prodName'),
      },
      {
        key: 'initial_qty',
        desc: this.translateService.instant('PurchasingOrder.initial_qty'),
      },
      {
        key: 'qty',
        desc: this.translateService.instant('PurchasingOrder.qty'),
      },
      {
        key: 'normalized_qty',
        desc: this.translateService.instant('PurchasingOrder.normalized_qty'),
      },
      {
        key: 'backorder',
        desc: this.translateService.instant('ProdConfigurator.backorder'),
      },
      {
        key: 'supplier_name',
        desc: this.translateService.instant('PurchasingOrder.supplier'),
      },
      {
        key: 'price',
        desc: this.translateService.instant('PurchasingOrder.price'),
      },
      {
        key: 'value',
        desc: this.translateService.instant('PurchasingOrder.value'),
      },
      {
        key: 'del',
        desc: this.translateService.instant('PurchasingOrder.del'),
      }
    );
  }

  public getProdFldHeader(fld: Field): string {
    let tmp = this.product_fields.find((x) => x.fld_code == fld.fld_code);
    return this.getFldTranslation(tmp);
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('drop');
    console.log(event);
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.storeSettings();
  }

  public fillItem(item: any) {
    this.addItemLine(item);
  }

  public getTempStr(el, fld_code) {
    return JSON.stringify(el.product[fld_code]);
  }

  public calculateValue(line: PurchasingorderLine): number {
    return line.normalized_qty * (line.price || 0);
  }

  public calculateTotalValue(): number {
    return this.lines.reduce((total, line) => {
      return total + this.calculateValue(line);
    }, 0);
  }
}
