import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpinnerType } from 'src/app/common/enums';
import { BaseFilter } from 'src/app/common/filters';
import { FilterHelper } from 'src/app/common/helper';
import { PagedData, ProductBatch, ReturnData } from 'src/app/model';
import { ERPWarehouse, ERPWhStocks } from 'src/app/models/erpwarehouse';
import { ERPSpdocline } from 'src/app/models/spdocline';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErpWarehouseService extends BaseService {
  router = inject(Router);
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getActiveWarehouses(): Observable<ERPWarehouse[]> {
    return this.get<ERPWarehouse[]>(this.apiUrl + 'erp/warehouses');
  }

  public getWarehousesTree(): Observable<ERPWarehouse> {
    return this.get<ERPWarehouse>(this.apiUrl + 'erp/warehousestree');
  }

  public storeWarehouse(
    warehoust: ERPWarehouse
  ): Observable<ReturnData<ERPWarehouse>> {
    return this.post<ReturnData<ERPWarehouse>>(
      this.apiUrl + 'erp/warehouses',
      warehoust
    );
  }

  public updateWarehouse(
    warehoust: ERPWarehouse
  ): Observable<ReturnData<ERPWarehouse>> {
    return this.put<ReturnData<ERPWarehouse>>(
      this.apiUrl + 'erp/warehouses/' + warehoust.id,
      warehoust
    );
  }

  public getAllWarehouses(filters: BaseFilter): Observable<ERPWarehouse[]> {
    let queryString = FilterHelper.QueryStringHelper(filters);
    return this.get<ERPWarehouse[]>(
      this.apiUrl + 'erp/warehouses_all' + queryString
    );
  }

  public getAllWarehousesPaged(
    filters: BaseFilter
  ): Observable<PagedData<ERPWarehouse[]>> {
    let queryString = FilterHelper.QueryStringHelper(filters, true);
    return this.get<PagedData<ERPWarehouse[]>>(
      this.apiUrl + 'erp/warehouses_paged' + queryString
    );
  }

  public getSingleWarehouse(id: number): Observable<ERPWarehouse> {
    return this.get<ERPWarehouse>(this.apiUrl + 'erp/warehouses/' + id);
  }

  public getProductWhStocks(product_id: number): Observable<ERPWhStocks[]> {
    return this.get<ERPWhStocks[]>(
      this.apiUrl + 'erp/product/' + product_id + '/stocks'
    );
  }

  public getProductActiveBatches(
    product_id: number
  ): Observable<ProductBatch[]> {
    return this.get<ProductBatch[]>(
      this.apiUrl + 'erp/product/' + product_id + '/active-batches'
    );
  }

  public getProductWhHistory(
    filters: BaseFilter,
    product_id: number,
    warehouse_id: number
  ): Observable<PagedData<ERPSpdocline>> {
    return this.get<PagedData<ERPSpdocline>>(
      this.apiUrl +
        'erp/history/' +
        product_id +
        '/' +
        warehouse_id +
        '/warehouse?page=' +
        filters.currentPage +
        '&pageSize=' +
        filters.pageSize
    );
  }

  filterWarehouses(activeWarehouses: ERPWarehouse[]) {
    // Define the filtering urls
    const routesForLeafWarehouses = [
      'erp_document',
      'pickingorder',
      'receivinglist',
      'inventory',
    ];
    const routesForCentralWarehouses = ['quote', 'order'];

    // Fetch the active url
    const activeRoute = this.router.url.split('/')[1];

    // Return the warehouses that are not parents of another warehouse
    if (routesForLeafWarehouses.includes(activeRoute)) {
      return this.findLeafWarehouses(activeWarehouses);
    }

    // Return the main warehouse, its immediate children and their children
    if (routesForCentralWarehouses.includes(activeRoute)) {
      return this.findThreeTopTierWarehouses(activeWarehouses);
    }

    // In case of different url, return all the active warehouses
    return activeWarehouses;
  }

  private findLeafWarehouses(activeWarehouses: ERPWarehouse[]) {
    const warehouseParents = activeWarehouses.map(
      (warehouse) => warehouse.warehouse_id
    );
    const uniqueParents = [...new Set(warehouseParents)];
    return activeWarehouses.filter(
      (warehouse) => !uniqueParents.includes(warehouse.id)
    );
  }

  private findThreeTopTierWarehouses(activeWarehouses: ERPWarehouse[]) {
    const mainWarehouse = activeWarehouses.filter(
      (warehouse) => warehouse.warehouse_id === null
    )[0];
    if (mainWarehouse) {
      const mainWarehouseChildren = activeWarehouses.filter(
        (warehouse) => warehouse.warehouse_id === mainWarehouse.id
      );
      const mainWarehouseChildrenIds = mainWarehouseChildren.map(
        (warehouse) => warehouse.id
      );
      const thirdTierWarehouses = activeWarehouses.filter((warehouse) =>
        mainWarehouseChildrenIds.includes(warehouse.warehouse_id)
      );
      return [mainWarehouse, ...mainWarehouseChildren, ...thirdTierWarehouses];
    } else {
      return [];
    }
  }
}
