import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { AsyncPipe } from '@angular/common';
import { ERPWarehouse } from '../../../../../models/erpwarehouse';

@Component({
  selector: 'app-warehouse-autocomplete',
  templateUrl: './warehouse-autocomplete.component.html',
  styleUrls: ['./warehouse-autocomplete.component.css'],
  standalone: true,
  imports: [
    MatFormField,
    MatAutocomplete,
    MatInput,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    AsyncPipe,
    MatOption,
  ],
})
export class WarehouseAutocompleteComponent implements OnInit, OnChanges {
  @Input() element: any;
  @Input() warehouses: ERPWarehouse[] = [];
  @Input() disabled: boolean;
  myControl = new FormControl<string | ERPWarehouse>('');
  filteredOptions: Observable<ERPWarehouse[]>;

  ngOnInit() {
    this.initializeFilter();
  }

  ngOnChanges() {
    if (this.disabled) {
      this.myControl.disable();
    }
    if (this.element?.warehouse_id && this.warehouses.length > 0) {
      const warehouse = this.warehouses.find(
        (x) => x.id === this.element.warehouse_id
      );
      if (warehouse) {
        this.myControl.setValue(warehouse, { emitEvent: false });
      }
    }
    this.initializeFilter();
  }

  private initializeFilter() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(this.myControl.value || ''), // Use existing value on start
      map((value) =>
        this._filter(typeof value === 'string' ? value : value?.title)
      )
    );
  }

  private _filter(value: string): ERPWarehouse[] {
    const filterValue = value?.toLowerCase() || '';
    return this.warehouses.filter((option) =>
      option.title.toLowerCase().includes(filterValue)
    );
  }

  onWarehouseSelected($event: MatAutocompleteSelectedEvent) {
    this.element.warehouse_id = $event.option.value.id;
  }

  displayFn(warehouse: ERPWarehouse | null): string {
    return warehouse && typeof warehouse === 'object' ? warehouse.title : '';
  }
}
