import { NotificationService } from 'src/app/services/notification.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BaseFilter, EntityItemFilter } from 'src/app/common/filters';
import { Entity, Field, SavedFilter, User } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { TimeHelper } from 'src/app/common/helper';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { take } from 'rxjs/operators';
import { DashboardService } from '../../../../services/dashboard.service';
import { SettingsService } from '../../../../services/settings.service';
import { MonthFilterComponent } from './month-filter/month-filter.component';
import { WeekFilterComponent } from './week-filter/week-filter.component';
import { UserService } from '../../../../services/user.service';
import { FilterService } from '../../../../services/filter.service';
import { SavedFiltersComponent } from './saved-filters/saved-filters.component';
import { LayoutService } from '../../../../services/layout.service';

@Component({
  selector: 'app-data-list-filters',
  templateUrl: './data-list-filters.component.html',
  styleUrls: ['./data-list-filters.component.css'],
})
export class DataListFiltersComponent implements OnInit {
  @Input() ENTITY: Entity;
  @Input() FILTERS: EntityItemFilter;
  @Output() getData = new EventEmitter<BaseFilter>();
  @ViewChildren('monthFilter') monthFilters: QueryList<MonthFilterComponent>;
  @ViewChildren('weekFilter') weekFilters: QueryList<WeekFilterComponent>;
  @ViewChild(UserFilterComponent) userFilterCom: UserFilterComponent;
  @ViewChild(SavedFiltersComponent) savedFiltersCom: SavedFiltersComponent;

  savedFilters: SavedFilter[] = [];
  fields: any[] = [];
  toogleFilters: boolean = true;
  prefs: Field[] = [];
  isHandset$ = this.layoutService.isHandset$;

  constructor(
    private readonly loginService: LoginService,
    private readonly entityService: EntityService,
    private readonly notificationService: NotificationService,
    private readonly layoutService: LayoutService,
    private readonly dashboardService: DashboardService,
    private readonly settingsService: SettingsService,
    private readonly userService: UserService,
    public readonly filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.fields = this.ENTITY.fields.filter((x) => x.hiddeOnFilters == 0);
    this.prefs = this.userService.getEntityFieldFilterPrefs(this.fields);
    this.addStepFilter();
    this.checkForSalesmanDateFilter();
    this.checkForTaskCreationDateRangeFilterOnBar();
  }

  changeFilter() {
    this.getData.emit(this.FILTERS);
  }

  public clearSearchValues(field: Field) {
    this.filterService.clearSearchValues(
      field,
      this.monthFilters,
      this.weekFilters
    );
  }

  public getDBFldTransateLabel(field: any): string {
    let lang = this.loginService.getLoginUser()?.lang;

    return lang == 'en' ? field.label_en : field.label_gr;
  }

  public saveFilters(newFilterName: string) {
    let newSavedFilter: SavedFilter = new SavedFilter();
    newSavedFilter.filters = [];
    newSavedFilter.title = newFilterName;
    newSavedFilter.entity_code = this.ENTITY.entity_code;

    for (let field of this.fields.filter((x) => x.search_from || x.search_to)) {
      let filterObj = {
        field_code: field.fld_code,
        search_from: field.search_from,
        search_to: field.search_to,
      };

      newSavedFilter.filters.push(filterObj);
    }

    newSavedFilter.trashed = this.FILTERS.trashed;
    newSavedFilter.connectedWith = this.FILTERS.connectedWith;
    newSavedFilter.createdAtSearchDateStart =
      this.FILTERS.createdAtSearchDateStart;
    newSavedFilter.createdAtSearchDateEnd = this.FILTERS.createdAtSearchDateEnd;
    newSavedFilter.itemId = this.FILTERS.itemId;
    newSavedFilter.ownerId = this.FILTERS.ownerId;
    newSavedFilter.strValue = this.FILTERS.strValue;

    this.entityService
      .addSavedFilters(newSavedFilter)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.savedFiltersCom.getSavedFiltersList();
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public applySavedFilter(filter: SavedFilter) {
    this.clearAllfilters();
    if (!filter) {
      return;
    }
    for (let field of this.fields) {
      let savedFilterIndex = filter.filters.findIndex(
        (x) => x.field_code == field.fld_code
      );

      if (
        savedFilterIndex >= 0 &&
        filter.filters[savedFilterIndex].search_from
      ) {
        field.search_from = filter.filters[savedFilterIndex].search_from;

        // For dropdown fields that were saved in a saved filter not as an array
        // of values but as a single value
        if (
          field.field_specs.fld_code == 'dropdown' &&
          !Array.isArray(field.search_from)
        ) {
          field.search_from = [field.search_from];
        }
        if (field.field_specs.fld_code == 'month') {
          const monthFilter = this.monthFilters.find(
            (item) => item.ITEM.id === field.id
          );
          if (monthFilter) {
            monthFilter.setValue(filter.filters[savedFilterIndex].search_from);
          }
        }
        if (field.field_specs.fld_code == 'week') {
          const weekFilter = this.weekFilters.find(
            (item) => item.ITEM.id === field.id
          );
          if (weekFilter) {
            weekFilter.setValue(filter.filters[savedFilterIndex].search_from);
          }
        }
      }
      if (savedFilterIndex >= 0 && filter.filters[savedFilterIndex].search_to) {
        field.search_to = filter.filters[savedFilterIndex].search_to;
      }
    }

    this.FILTERS.trashed = +filter.trashed;
    this.FILTERS.connectedWith = filter.connectedWith;
    this.FILTERS.ownerId = filter.ownerId;
    this.FILTERS.createdAtSearchDateStart = filter.createdAtSearchDateStart;
    this.FILTERS.createdAtSearchDateEnd = filter.createdAtSearchDateEnd;
    this.FILTERS.itemId = filter.itemId;
    this.FILTERS.strValue = filter.strValue;
    this.userFilterCom.search_from = filter.ownerId;
  }

  public autoFillFrom(field: Field) {
    this.filterService.autoFillFrom(field);
  }

  public clearConnectedWith() {
    this.FILTERS.connectedWith = null;
  }

  public clearStrValue() {
    this.FILTERS.strValue = null;
  }
  public clearOwnerId() {
    this.userFilterCom.search_from = null;
    this.FILTERS.ownerId = null;
  }

  public clearCreatedAtSearchDate() {
    this.FILTERS.createdAtSearchDateStart = null;
    this.FILTERS.createdAtSearchDateEnd = null;
  }

  public clearItemId() {
    this.FILTERS.itemId = null;
  }

  public clearAllfilters() {
    this.clearConnectedWith();
    this.clearStrValue();
    this.clearOwnerId();
    this.clearCreatedAtSearchDate();
    this.clearItemId();
    this.FILTERS.trashed = 0;
    this.filterService.creationDateRangeFilter.set('');

    for (let field of this.fields) {
      this.clearSearchValues(field);
    }
  }

  public selectOwnerFiler(user: User) {
    this.FILTERS.ownerId = user.id;
  }

  private addStepFilter() {
    let stepFields = this.ENTITY.fields.filter(
      (x) => x.field_specs.fld_code === 'step'
    );
    if (stepFields.length > 0) {
      let stepNames = '';
      stepFields.forEach((field) => {
        stepNames += this.getDBFldTransateLabel(field) + '|';
      });
      stepNames += 'Closed';
      let stepFilter = {
        id: -23,
        field_specs: { fld_code: 'dropdown' },
        fld_code: 'current_step',
        label_en: 'Current Step',
        label_gr: 'Τρέχον Βήμα',
        for_entity: stepNames,
        search_from: null,
      };
      this.fields.push(stepFilter);
      this.FILTERS.stepValue = null;
    }
  }

  private checkForSalesmanDateFilter() {
    if (this.dashboardService.applySalesmanEntityDateFilter.valueOf()) {
      this.settingsService
        .getSettings(`dashboardFilterFieldId-${this.ENTITY.entity_code}`)
        .pipe(take(1))
        .subscribe((value) => {
          if (value.length > 0) {
            const filterFieldId = Number(value[0].value);
            const index = this.ENTITY.fields.findIndex(
              (field) => field.id === filterFieldId
            );
            if (index !== -1) {
              this.ENTITY.fields[index].search_from =
                TimeHelper.getDatetimeStringNew(TimeHelper.getStartOfWeek());
              this.ENTITY.fields[index].search_to =
                TimeHelper.getDatetimeStringNew(TimeHelper.getEndOfWeek());
              this.FILTERS.searchFields.push(this.ENTITY.fields[index]);
            }
          }
          this.entityService.getEntityListWithSalesmanFilters.next(true);
        });
    }
  }

  applyCreationDateRangeFilter() {
    this.filterService.applyCreationDateRangeFilter(this.ENTITY);
  }

  clearCreationDateRangeFilter() {
    this.filterService.clearCreationDateRangeFilter(this.ENTITY);
  }

  private checkForTaskCreationDateRangeFilterOnBar() {
    const filter = localStorage.getItem('taskCreationDateRangeFilterOnBar');
    if (filter) {
      this.filterService.creationDateRangeFilterOnBar.set(true);
    }
  }

  protected readonly event = event;

  refreshPrefs(newPrefs: Field[]) {
    this.prefs = [...newPrefs];
  }
}
