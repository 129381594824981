import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class ProductListService extends BaseService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly settingsService: SettingsService
  ) {
    super(httpClient);
  }

  public makeGridColumns() {
    return [
      {
        key: 'id',
        desc: '#',
      },
      {
        key: 'product_name',
        desc: this.translateService.instant('QuoteConfigurator.prodName'),
      },
      {
        key: 'qty',
        desc: this.translateService.instant('QuoteConfigurator.qty'),
      },
      {
        key: 'unit',
        desc: this.translateService.instant('QuoteConfigurator.unit'),
      },
      {
        key: 'discount',
        desc: this.translateService.instant('QuoteConfigurator.discount'),
      },
      {
        key: 'discount2',
        desc:
          this.translateService.instant('QuoteConfigurator.discount') + ' ' + 2,
      },
      {
        key: 'customPrice',
        desc: this.translateService.instant('ProdConfigurator.price'),
      },
      {
        key: 'vat',
        desc: this.translateService.instant('QuoteConfigurator.vat'),
      },
      {
        key: 'backorder',
        desc: this.translateService.instant('ProdConfigurator.backorder'),
      },
      {
        key: 'piecePrice',
        desc: this.translateService.instant('QuoteConfigurator.piecePrice'),
      },
      {
        key: 'smallBoxPrice',
        desc: this.translateService.instant('QuoteConfigurator.smallBoxPrice'),
      },
      {
        key: 'boxPrice',
        desc: this.translateService.instant('QuoteConfigurator.boxPrice'),
      },
      {
        key: 'paletPrice',
        desc: this.translateService.instant('QuoteConfigurator.paletPrice'),
      },
      {
        key: 'nettoPrice',
        desc: this.translateService.instant('ProdConfigurator.NettoPrice'),
      },
      {
        key: 'stock',
        desc: this.translateService.instant('QuoteConfigurator.stock'),
      },
      {
        key: 'availability',
        desc: this.translateService.instant('QuoteConfigurator.availability'),
      },
      {
        key: 'totalPieces',
        desc: this.translateService.instant('QuoteConfigurator.totalPieces'),
      },
      {
        key: 'totalPrice',
        desc: this.translateService.instant('QuoteConfigurator.totalPrice'),
      },
      {
        key: 'warehouse',
        desc: this.translateService.instant('ERPWarehouse.warehouse'),
      },
      {
        key: 'containers',
        desc: this.translateService.instant('QuoteConfigurator.containers'),
      },
      {
        key: 'extranumeric1',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld1')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 1',
      },
      {
        key: 'extranumeric2',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld2')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 2',
      },
      {
        key: 'extranumeric3',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld3')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 3',
      },
      {
        key: 'extrastr1',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld1')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 4',
      },
      {
        key: 'extrastr2',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld2')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 5',
      },
      {
        key: 'extrastr3',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld3')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 6',
      },
      {
        key: 'extraDrpDown1',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 7',
      },
      {
        key: 'extraDrpDown2',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 8',
      },
      {
        key: 'extraDrpDown3',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 9',
      },
      {
        key: 'qr-scanner-search',
        desc: this.translateService.instant(
          'QuoteConfigurator.qrScannerSearch'
        ),
      },
      {
        key: 'qr-scanner-validator',
        desc: this.translateService.instant(
          'QuoteConfigurator.qrScannerValidator'
        ),
      },
      {
        key: 'productBatch',
        desc: this.translateService.instant('QuoteConfigurator.productBatch'),
      },
      {
        key: 'del',
        desc: this.translateService.instant('QuoteConfigurator.del'),
      },
      {
        key: 'order',
        desc: this.translateService.instant('QuoteConfigurator.orderLine'),
      },
      {
        key: 'containers',
        desc: this.translateService.instant('QuoteConfigurator.containers'),
      },
    ];
  }
}
