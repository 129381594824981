import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import {
  CalendarAbsence,
  CalendarEntityEvent,
  CalendarMeeting,
  CalendarProductionOrder,
  CalendarShift,
  CalendarTask,
  ReturnData,
  CalendarPickingOrder,
  CalendarReceivingList,
  CalendarInventory
} from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getCalendarTasks(calendarId): Observable<CalendarTask[]> {
    return this.get<CalendarTask[]>(
      this.apiUrl + `calendar/${calendarId}/tasks`
    );
  }

  public getCalendarShifts(calendarId): Observable<CalendarShift[]> {
    return this.get<CalendarShift[]>(
      this.apiUrl + `calendar/${calendarId}/shifts`
    );
  }

  public getCalendarAbsences(calendarId): Observable<CalendarAbsence[]> {
    return this.get<CalendarAbsence[]>(
      this.apiUrl + `calendar/${calendarId}/absences`
    );
  }

  public getCalendarMeetings(calendarId): Observable<CalendarMeeting[]> {
    return this.get<CalendarMeeting[]>(
      this.apiUrl + `calendar/${calendarId}/meetings`
    );
  }

  public getCalendarProductionOrders(
    calendarId
  ): Observable<CalendarProductionOrder[]> {
    return this.get<CalendarProductionOrder[]>(
      this.apiUrl + `calendar/${calendarId}/production-orders`
    );
  }

  public getCalendarPickinOrders(
    calendarId
  ): Observable<CalendarPickingOrder[]> {
    return this.get<CalendarPickingOrder[]>(
      this.apiUrl + `calendar/${calendarId}/pickingorders`
    );
  }

  public getCalendarReceivingLists(
    calendarId
  ): Observable<CalendarReceivingList[]> {
    return this.get<CalendarReceivingList[]>(
      this.apiUrl + `calendar/${calendarId}/receivinglists`
    );
  }

  public getCalendarInventory(
    calendarId
  ): Observable<CalendarInventory[]> {
    return this.get<CalendarInventory[]>(
      this.apiUrl + `calendar/${calendarId}/inventory`
    );
  }

  public getCalendarEvents(calendarId): Observable<CalendarEntityEvent[]> {
    return this.get<CalendarEntityEvent[]>(
      this.apiUrl + `calendar/${calendarId}/events`
    );
  }

  public addCalendarEvent(
    calendarId,
    data
  ): Observable<ReturnData<CalendarEntityEvent>> {
    return this.post<ReturnData<CalendarEntityEvent>>(
      this.apiUrl + `calendar/${calendarId}/event`,
      data
    );
  }

  public updateCalendarEvent(
    calendarId,
    eventId,
    data
  ): Observable<ReturnData<CalendarEntityEvent>> {
    return this.put<ReturnData<CalendarEntityEvent>>(
      this.apiUrl + `calendar/${calendarId}/event/${eventId}`,
      data
    );
  }

  public deleteCalendarEvent(
    calendarId,
    eventId
  ): Observable<CalendarEntityEvent[]> {
    return this.delete<CalendarEntityEvent[]>(
      this.apiUrl + `calendar/${calendarId}/event/${eventId}`
    );
  }
}
