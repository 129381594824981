.example-month-picker .mat-calendar-period-button {
  pointer-events: none;
}

.example-month-picker .mat-calendar-arrow {
  display: none;
}

.title {
  color: black;
  font-weight: bold;
}
