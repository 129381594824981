import { Component, inject, Input, OnChanges } from '@angular/core';
import { ErpWarehouseService } from '../../../../../services/erp/erp-warehouse.service';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ConfQuoteLine, ProductBatch } from '../../../../../model';
import { map, take } from 'rxjs/operators';
import { Observable, startWith } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-product-batch-autocomplete',
  standalone: true,
  imports: [
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormField,
    MatInput,
    MatOption,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './product-batch-autocomplete.component.html',
  styleUrl: './product-batch-autocomplete.component.css',
})
export class ProductBatchAutocompleteComponent implements OnChanges {
  @Input() productId!: number;
  @Input() quoteLine!: ConfQuoteLine;

  productBatches: ProductBatch[] = [];
  filteredOptions: Observable<ProductBatch[]>;
  myControl = new FormControl<string | ProductBatch>('');

  private readonly erpWarehouseService = inject(ErpWarehouseService);

  ngOnChanges() {
    this.erpWarehouseService
      .getProductActiveBatches(this.productId)
      .pipe(take(1))
      .subscribe((res) => {
        this.productBatches = res;
        this.checkForSavedBatch();
        this.initializeFilter();
      });
  }

  displayFn(batch: ProductBatch | null): string {
    return batch && typeof batch === 'object' ? batch.code : '';
  }

  private _filter(value: string): ProductBatch[] {
    const filterValue = value?.toLowerCase() || '';
    return this.productBatches.filter((option) =>
      option.code.toLowerCase().includes(filterValue)
    );
  }

  private initializeFilter() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(this.myControl.value || ''), // Use existing value on start
      map((value) =>
        this._filter(typeof value === 'string' ? value : value?.code)
      )
    );
  }

  onProductBatchSelected($event: MatAutocompleteSelectedEvent) {
    this.quoteLine.batch_id = +$event.option.value.spdoc_id;
  }

  private checkForSavedBatch() {
    if (this.quoteLine?.batch_id && this.productBatches.length > 0) {
      const productBatch = this.productBatches.find(
        (x) => x.spdoc_id === this.quoteLine.batch_id
      );
      if (productBatch) {
        this.myControl.setValue(productBatch, { emitEvent: false });
      }
    }
  }
}
