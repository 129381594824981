<mat-card class="filterCard">
  <table>
    <tr>
      <td width="10%">{{ 'Generic.linkEnity' | translate }}</td>
      <td width="30%">
        <mat-form-field style="width: 180px; margin-top: 4px">
          <mat-label>#</mat-label>
          <input matInput [(ngModel)]="FILTERS.connectedWith" type="number" />
        </mat-form-field>
      </td>
      <td width="50%">
        <mat-form-field>
          <mat-label>{{ 'Company.entities' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedConnectEntity">
            @for (entity of entities; track entity.id) {
              <mat-option [value]="entity">
                {{ getEntityNameTranslate(entity) }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          style="display: inline"
          (click)="selectEntityIDiag()"
        >
          <mat-icon color="primary">search</mat-icon>
        </button>
      </td>
      <td width="10%">
        @if (FILTERS.connectedWith) {
          <button
            mat-icon-button
            color="primary"
            (click)="clearConnectedWith()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
      </td>
    </tr>

    @for (attr of fields; track attr.id) {
      <tr style="height: 60px">
        <td width="10%">
          <button
            mat-icon-button
            [ngStyle]="{
              'background-color': showSearchField(attr.id)
                ? '#babfc4'
                : 'transparent'
            }"
            matTooltip="pin on top"
            (click)="modifyFilterPreferences(attr)"
          >
            <mat-icon>push_pin</mat-icon>
          </button>
        </td>
        <td width="30%">
          <mat-label>{{ attr | translateFieldLabel }} </mat-label>
        </td>
        @if (!showSearchField(attr.id)) {
          <td>
            @if (attr.field_specs.fld_code == 'singleEntity') {
              <section style="display: flex">
                <mat-form-field style="width: 140px">
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="attr.search_from"
                  />
                </mat-form-field>
                <app-single-entity-filter [ITEM]="attr">
                </app-single-entity-filter>
              </section>
            }

            @if (attr.field_specs.fld_code == 'scale') {
              <section>
                <mat-slider
                  style="width: 189px"
                  max="5"
                  min="1"
                  step="1"
                  discrete
                  color="primary"
                  showTickMarks
                >
                  <input matSliderThumb [(ngModel)]="attr.search_from" />
                </mat-slider>
              </section>
            }

            @if (attr.field_specs.fld_code == 'user') {
              <section>
                <app-user-filter
                  [search_from]="attr.search_from"
                  [ITEM]="attr"
                  [SHOWUSER]="!!attr.search_from"
                ></app-user-filter>
              </section>
            }

            @if (attr.field_specs.fld_code == 'number') {
              <mat-form-field class="full-width">
                <mat-label>Από</mat-label>
                <input matInput type="number" [(ngModel)]="attr.search_from" />
              </mat-form-field>
            }

            @if (
              attr.field_specs.fld_code == 'text' ||
              attr.field_specs.fld_code == 'email' ||
              attr.field_specs.fld_code == 'tel'
            ) {
              <mat-form-field style="margin-right: 10px">
                <input matInput [(ngModel)]="attr.search_from" />
              </mat-form-field>
            }

            @if (attr.field_specs.fld_code == 'month') {
              <app-month-filter [ITEM]="attr"></app-month-filter>
            }

            @if (attr.field_specs.fld_code == 'week') {
              <app-week-filter [ITEM]="attr"></app-week-filter>
            }

            @if (attr.field_specs.fld_code == 'dropdown') {
              <app-dropdown-filter [ITEM]="attr"></app-dropdown-filter>
            }

            @if (attr.field_specs.fld_code == 'datetime') {
              <section class="full-width">
                <app-datetime-filter
                  [FROMORTO]="'from'"
                  [ITEM]="attr"
                  (changeDate)="checkForCreationDateRangeFilter(attr)"
                ></app-datetime-filter>
              </section>
            }

            @if (attr.field_specs.fld_code == 'checkbox') {
              <section class="full-width">
                <app-checkbox-filter [ITEM]="attr"> </app-checkbox-filter>
              </section>
            }

            @if (attr.field_specs.fld_code == 'productlist') {
              <section style="display: flex">
                <mat-form-field style="width: 140px">
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="attr.search_from"
                  />
                </mat-form-field>
                <app-single-entity-filter [ITEM]="attr">
                </app-single-entity-filter>
              </section>
            }
          </td>
          <td>
            @if (attr.field_specs.fld_code == 'scale') {
              <section>
                <mat-slider
                  style="width: 189px"
                  max="5"
                  min="1"
                  step="1"
                  discrete
                  color="primary"
                  showTickMarks
                >
                  <input
                    matSliderThumb
                    [(ngModel)]="attr.search_to"
                    (ngModelChange)="autoFillFrom(attr)"
                  />
                </mat-slider>
              </section>
            }

            @if (attr.field_specs.fld_code == 'number') {
              <mat-form-field class="full-width">
                <mat-label>Έως</mat-label>
                <input
                  matInput
                  type="number"
                  [(ngModel)]="attr.search_to"
                  (ngModelChange)="autoFillFrom(attr)"
                />
              </mat-form-field>
            }

            @if (attr.field_specs.fld_code == 'datetime') {
              <section>
                <app-datetime-filter
                  [FROMORTO]="'το'"
                  [ITEM]="attr"
                  (changeDate)="autoFillFrom(attr)"
                >
                </app-datetime-filter>
              </section>
            }
          </td>
          <td>
            <div style="width: 60px">
              @if (attr.search_to || attr.search_from) {
                <button
                  mat-icon-button
                  color="primary"
                  (click)="clearSearchValues(attr)"
                >
                  <mat-icon color="warn">clear</mat-icon>
                </button>
              }
            </div>
          </td>
        }
      </tr>
    }

    @if (ENTITY.entity_code === 'tasks') {
      <tr>
        <td width="10%">
          <button
            mat-icon-button
            matTooltip="pin on top"
            [ngStyle]="{
              'background-color': filterService.creationDateRangeFilterOnBar()
                ? '#babfc4'
                : 'transparent'
            }"
            (click)="toggleCreationDateRangeFilter()"
          >
            <mat-icon>push_pin</mat-icon>
          </button>
        </td>
        <td width="30%">
          <mat-label>{{
            'Generic.taskStartDateRangeFilter' | translate
          }}</mat-label>
        </td>
        <td>
          @if (!filterService.creationDateRangeFilterOnBar()) {
            <mat-form-field>
              <mat-select
                [(ngModel)]="filterService.creationDateRangeFilter"
                (ngModelChange)="applyCreationDateRangeFilter()"
              >
                <mat-option value="currentWeek">{{
                  'Generic.currentWeek' | translate
                }}</mat-option>
                <mat-option value="currentMonth">{{
                  'Generic.currentMonth' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          }
        </td>
        <td></td>
        <td>
          <div style="width: 60px">
            @if (
              filterService.creationDateRangeFilter() !== '' &&
              !filterService.creationDateRangeFilterOnBar()
            ) {
              <button
                mat-icon-button
                color="primary"
                (click)="clearCreationDateRangeFilter()"
              >
                <mat-icon color="warn">clear</mat-icon>
              </button>
            }
          </div>
        </td>
      </tr>
    }
  </table>
</mat-card>
