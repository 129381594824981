import { Entdata, Sprint, TaskDto, Taskgroup } from '../model';
import { BaseFilter } from './filters';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';

export class DtoConverter {
  public EntdataToTaskDto(item: Entdata): TaskDto {
    let task: TaskDto = new TaskDto();

    task.id = item.id;

    let assigneeIndex = item.fields.findIndex(
      (x) => x.field.fld_code === 'task_assignee'
    );

    task.task_assignee = item.fields[assigneeIndex]['value'];
    task.task_desc =
      item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_desc')
      ].value;
    task.task_duration =
      item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_duration')
      ].value;
    task.task_group = item['task_group'] as Taskgroup;
    task.task_childtask =
      +item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_childtask')
      ].value;
    let startdateStr: string =
      item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_startdate')
      ].value;

    if (startdateStr) {
      var s = startdateStr?.split(' ')[0]?.split('-');

      var m = startdateStr.includes('T')
        ? startdateStr?.split('T')[1]?.split(':')
        : startdateStr?.split(' ')[1]?.split(':');
      var d = new Date(
        parseInt(s[0]),
        parseInt(s[1]) - 1,
        parseInt(s[2]),
        parseInt(m[0]),
        parseInt(m[1]),
        0,
        0
      );
      task.task_startdate = d;
    } else {
      task.task_startdate = null;
    }

    let enddateStr: string =
      item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_enddate')
      ].value;

    if (enddateStr) {
      var s = enddateStr.split(' ')[0].split('-');
      var m = startdateStr.split(' ')[1].split(':');
      var d = new Date(
        parseInt(s[0]),
        parseInt(s[1]) - 1,
        parseInt(s[2]),
        parseInt(m[0]),
        parseInt(m[1]),
        0,
        0
      );
      task.task_enddate = d;
    } else {
      task.task_enddate = null;
    }

    task.task_status =
      item.fields[
        item.fields.findIndex((x) => x.field.fld_code === 'task_status')
      ].value;
    let completed_atIndex = item.fields.findIndex(
      (x) => x.field.fld_code === 'task_status' && x.value === 'Done'
    );
    if (completed_atIndex >= 0) {
      task.completed_at = new Date(
        item.fields[completed_atIndex]['updated_at']
      );
    } else {
      task.completed_at = null;
    }

    task.created_at = item.created_at;
    task.updated_at = item.updated_at;

    return task;
  }
}

export class TimeHelper {
  public static getDatetimeString(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += date.getDate() + ' ';
    datetimestr += date.getHours() + ':';
    datetimestr += date.getMinutes();

    return datetimestr;
  }

  public static getDatetimeStringNew(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += ('0' + date.getDate()).slice(-2) + ' ';
    datetimestr += ('0' + date.getHours()).slice(-2) + ':';
    datetimestr += ('0' + date.getMinutes()).slice(-2);

    return datetimestr;
  }

  public static getDateString(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += ('0' + date.getDate()).slice(-2);

    return datetimestr;
  }

  public static getDateFromString(dateString: string): Date {
    var s = dateString.split(' ')[0]?.split('-');
    var m = dateString.includes('T')
      ? dateString?.split('T')[1]?.split(':')
      : dateString?.split(' ')[1]?.split(':');
    //console.log("format date:"+new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0));
    return new Date(
      parseInt(s[0]),
      parseInt(s[1]) - 1,
      parseInt(s[2]),
      parseInt(m[0]),
      parseInt(m[1]),
      0,
      0
    );
  }

  public static getNowDate() {
    return new Date();
  }

  public static getStartOfCurrentMonth() {
    let nowDate = this.getNowDate();
    return new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
  }

  public static getEndOfCurrentMonth() {
    let nowDate = this.getNowDate();
    return new Date(
      nowDate.getFullYear(),
      nowDate.getMonth() + 1,
      0,
      23,
      59,
      59
    );
  }

  public static getPreviousMonth() {
    let currentDate = this.getNowDate();
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    );
  }

  public static getStartOfPreviousMonth() {
    let previousMonth = this.getPreviousMonth();
    return new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
  }

  public static getEndOfPreviousMonth() {
    let previousMonth = this.getPreviousMonth();
    let lastDay = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth() + 1,
      0
    );
    return new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth(),
      lastDay.getDate(),
      23,
      59,
      59
    );
  }

  public static getStartOfWeek() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Calculate the difference between the current day and Monday
    const daysUntilMonday = (dayOfWeek + 6) % 7;
    // Subtract the difference to get the start of the current week (Monday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - daysUntilMonday);
    // Set hours, minutes, and seconds to 0
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  }

  public static getEndOfWeek() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Calculate the difference between the current day and Sunday (end of the week)
    const daysUntilSunday = (7 - dayOfWeek) % 7;
    // Add the difference to get the start of the next week (Sunday)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + daysUntilSunday);
    // Set hours, minutes, seconds, and milliseconds to the end of the day
    endOfWeek.setHours(23, 59, 59, 999);
    return endOfWeek;
  }
}

export class DurationHelper {
  public durationToMinutes24h(duration: string): number {
    let minutes = 0;
    switch (duration) {
      case '20m':
        minutes = 20;
        break;
      case '1h':
        minutes = 60;
        break;
      case '2h':
        minutes = 120;
        break;
      case '3h':
        minutes = 180;
        break;
      case '5h':
        minutes = 300;
        break;
      case '1d':
        minutes = 1440;
        break;
      case '2d':
        minutes = 2880;
        break;
      case '3d':
        minutes = 4320;
        break;
      case '5d':
        minutes = 7200;
        break;
      case '8d':
        minutes = 11520;
        break;
      case '13d':
        minutes = 18720;
        break;

      default:
        minutes = 0;
        break;
    }

    return minutes;
  }

  public durationToMinutes8h(duration: string): number {
    let minutes = 0;
    switch (duration) {
      case '20m':
        minutes = 20;
        break;
      case '1h':
        minutes = 60;
        break;
      case '2h':
        minutes = 120;
        break;
      case '3h':
        minutes = 180;
        break;
      case '5h':
        minutes = 300;
        break;
      case '1d':
        minutes = 480;
        break;
      case '2d':
        minutes = 960;
        break;
      case '3d':
        minutes = 1440;
        break;
      case '5d':
        minutes = 2400;
        break;
      case '8d':
        minutes = 3840;
        break;
      case '13d':
        minutes = 6240;
        break;

      default:
        minutes = 0;
        break;
    }

    return minutes;
  }

  public getIdialDuration(sprint: Sprint) {
    const sum: number = sprint.tasksDto
      .filter((x) => x.task_duration)
      .reduce(
        (sum, task) => sum + this.durationToMinutes8h(task.task_duration),
        0
      );
    return sum / 60;
  }

  public getCompletedDuration(sprint: Sprint) {
    const sum: number = sprint.tasksDto
      .filter(
        (x) => x.task_status && x.task_duration && x.task_status == 'Done'
      )
      .reduce(
        (sum, task) => sum + this.durationToMinutes8h(task.task_duration),
        0
      );
    return sum / 60;
  }

  public getDiffInNumOfDays(minDate: Date, startDate: Date): number {
    let diff: number;
    startDate.setHours(0, 0, 0, 0);

    let diffTime = startDate.getTime() - minDate.getTime();
    diff = Math.round(diffTime / (1000 * 3600 * 24));

    return diff;
  }
}

export class FilterHelper {
  public static QueryStringHelper(
    filter: BaseFilter,
    activePagination = false
  ) {
    let queryString = '?trashed=' + filter.trashed;

    if (filter.strValue != '') {
      queryString += '&strValue=' + filter.strValue;
    }

    if (activePagination) {
      queryString += '&page=' + filter.currentPage;
      queryString += '&pageSize=' + filter.pageSize;
    }

    return queryString;
  }
}

export class TransformationHelper {
  public static CanEntityTransformToErpHelper(entity: string) {
    return (
      entity == 'order' ||
      entity == 'productionorder' ||
      entity == 'purchasingorder' ||
      entity == 'pickingorder' ||
      entity == 'receivinglist' ||
      entity == 'inventory'
    );
  }
}

export class EntityHelper {
  public static ChooseDisplayFieldForEntity(entityCode: string) {
    const displayEntityField = {
      documents: 'doc_desc',
      tasks: 'task_desc',
      projects: 'project_title',
      country: 'country_name',
      competitor: 'competitor_name',
      contact: 'contact_last_name',
      account: 'account_name',
      supplier: 'supplier_name',
      lead: 'lead_name',
      product: 'product_name',
      order: 'order_title',
      service: 'service_description',
      quote: 'quote_title',
      opportunity: 'opportunity_title',
      invoice: 'invoice_info_label',
      installations: 'installation_name',
      report: 'report_name',
      productionorder: 'productionorder_product',
      purchasingorder: 'purchasingorder_product',
      productionschedule: 'productionschedule_scheduling_month',
      channel: 'channel_title',
      employee: 'employee_lastname',
      hrticket: 'hrticket_poblem_description',
      absence: 'absence_description',
      resume: 'resume_description',
      interview: 'interview_description',
      training: 'training_name',
      shift: 'shift_description',
      calendar: 'calendar_title',
      address: 'address_customer',
      machinery: 'machinery_name',
      spare_part: 'spare_part_name',
      maintenance_file: 'maintenance_file_desc',
      breakdown: 'breakdown_description',
      repair_order: 'repair_order_spare_order',
      prodrecipe: 'prodrecipe_product',
      meetings: 'meetings_description',
      expense: 'expense_name',
      expensesheet: 'expensesheet_title',
      pickingorder: 'pickingorder_title',
      receivinglist: 'receivinglist_category',
      inventory: 'inventory_date',
    };
    return displayEntityField[entityCode];
  }
}

export class ProductListHelper {
  public static fetchAllDisplayColumns() {
    return [
      'id',
      'product_name',
      'qty',
      'unit',
      'discount',
      'discount2',
      'customPrice',
      'vat',
      'piecePrice',
      'smallBoxPrice',
      'boxPrice',
      'paletPrice',
      'nettoPrice',
      'stock',
      'availability',
      'totalPieces',
      'totalPrice',
      'warehouse',
      'containers',
      'extranumeric1',
      'extranumeric2',
      'extranumeric3',
      'extrastr1',
      'extrastr2',
      'extraDrpDown1',
      'extraDrpDown2',
      'extraDrpDown3',
      'extrastr3',
      'del',
      'order',
      'qr-scanner-search',
      'qr-scanner-validator',
      'productBatch',
    ];
  }

  public static fetchPORLDisplayColumns() {
    return [
      'id',
      'product_name',
      'qty',
      'backorder',
      'warehouse',
      'productBatch',
      'qr-scanner-search',
      'qr-scanner-validator',
      'del',
    ];
  }

  public static fetchInventoryDisplayColumns() {
    return [
      'id',
      'product_name',
      'qty',
      'warehouse',
      'qr-scanner-search',
      'qr-scanner-validator',
      'del',
    ];
  }

  public static fetchDefaultDisplayColumns() {
    return [
      'id',
      'product_name',
      'qty',
      'unit',
      'discount',
      'discount2',
      'customPrice',
      'vat',
      'availability',
      'totalPieces',
      'totalPrice',
      'del',
    ];
  }
}
