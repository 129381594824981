import { Injectable, QueryList, signal } from '@angular/core';
import { Entity, Field } from '../model';
import { TimeHelper } from '../common/helper';
import { MonthFilterComponent } from '../common/entity/data-list/data-list-filters/month-filter/month-filter.component';
import { WeekFilterComponent } from '../common/entity/data-list/data-list-filters/week-filter/week-filter.component';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  creationDateRangeFilter = signal<string>('');
  creationDateRangeFilterOnBar = signal<boolean>(false);

  public autoFillFrom(field: Field) {
    if (field.fld_code == 'task_startdate') {
      this.creationDateRangeFilter.set('');
    }
    if (field.search_from) {
      return;
    }

    if (
      field.field_specs.fld_code == 'number' ||
      field.field_specs.fld_code == 'scale'
    ) {
      field.search_from = '0';
    } else if (field.field_specs.fld_code == 'datetime') {
      field.search_from = TimeHelper.getDatetimeString(new Date());
    }
  }

  clearSearchValues(
    field: Field,
    monthFilters: QueryList<MonthFilterComponent>,
    weekFilters: QueryList<WeekFilterComponent>
  ) {
    delete field.search_from;
    delete field.search_to;
    if (field.field_specs.fld_code == 'month') {
      const monthFilter = monthFilters.find(
        (item) => item.ITEM.id === field.id
      );
      if (monthFilter) {
        monthFilter.clearValue();
      }
    }
    if (field.field_specs.fld_code == 'week') {
      const weekFilter = weekFilters.find((item) => item.ITEM.id === field.id);
      if (weekFilter) {
        weekFilter.clearValue();
      }
    }

    if (field.fld_code == 'task_startdate') {
      this.creationDateRangeFilter.set('');
    }
  }

  applyCreationDateRangeFilter(ENTITY: Entity) {
    const index = ENTITY.fields.findIndex(
      (field) => field.fld_code === 'task_startdate'
    );
    if (index !== -1) {
      ENTITY.fields[index].search_from =
        this.creationDateRangeFilter() === 'currentWeek'
          ? TimeHelper.getDatetimeStringNew(TimeHelper.getStartOfWeek())
          : TimeHelper.getDatetimeStringNew(
              TimeHelper.getStartOfCurrentMonth()
            );
      ENTITY.fields[index].search_to =
        this.creationDateRangeFilter() === 'currentWeek'
          ? TimeHelper.getDatetimeStringNew(TimeHelper.getEndOfWeek())
          : TimeHelper.getDatetimeStringNew(TimeHelper.getEndOfCurrentMonth());
    }
  }

  clearCreationDateRangeFilter(ENTITY: Entity) {
    this.creationDateRangeFilter.set('');
    const index = ENTITY.fields.findIndex(
      (field) => field.fld_code === 'task_startdate'
    );
    if (index !== -1) {
      delete ENTITY.fields[index].search_from;
      delete ENTITY.fields[index].search_to;
    }
  }
}
