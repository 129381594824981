<div class="GridDiv">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-table
    #notesTable
    class="notes-table"
    [dataSource]="dataSource"
    cdkDropList
    cdkDropListOrientation="horizontal"
  >
    <ng-container matColumnDef="field_1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[0].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_1"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[1].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_2"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[2].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_3"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_4">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[3].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_4"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_5">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[4].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_5"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_6">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[5].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_6"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_7">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[6].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_7"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_8">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[7].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_8"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_9">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[8].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_9"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_10">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{ allColumns[9].desc }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            type="text"
            [(ngModel)]="element.column_10"
            [disabled]="disabled"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('noteListextraDrpDown1')[0]">{{
          settingsService.getSavedSettings('noteListextraDrpDown1')[0]?.value
          }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('noteListextraDrpDown1')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}7</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select [(ngModel)]="element.drpDownFld1" class="full-width" >
            <mat-option *ngFor="
                    let option of settingsService.getSavedSettings(
                      'notesListDrpDownValues1'
                    )
                  " [value]="option.value">
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="extraDrpDown2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('noteListextraDrpDown2')[0]">{{
          settingsService.getSavedSettings('noteListextraDrpDown2')[0]?.value
          }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('noteListextraDrpDown2')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}8</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select [(ngModel)]="element.drpDownFld2" class="full-width">
            <mat-option *ngFor="
                    let option of settingsService.getSavedSettings(
                      'notesListDrpDownValues2'
                    )
                  " [value]="option.value">
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="extraDrpDown3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('noteListextraDrpDown3')[0]">{{
          settingsService.getSavedSettings('noteListextraDrpDown3')[0]?.value
          }}
        </span>
        <span *ngIf="!settingsService.getSavedSettings('noteListextraDrpDown3')[0]">
          {{ 'ProdConfigurator.extrFld' | translate }}9</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select [(ngModel)]="element.drpDownFld3" class="full-width">
            <mat-option *ngFor="
                    let option of settingsService.getSavedSettings(
                      'notesListDrpDownValues3'
                    )
                  " [value]="option.value">
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        @if (!disabled) {
          <button mat-icon-button color="warn" (click)="removeLine(i)">
            <mat-icon>clear</mat-icon>
          </button>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"> </mat-row>
  </mat-table>

  <button
    mat-raised-button
    color="primary"
    class="new-line-button"
    (click)="addNoteLine(null)"
    [disabled]="disabled"
  >
    {{ 'Generic.addNewLine' | translate }}
  </button>
</div>

<mat-menu #columns="matMenu">
  <mat-selection-list
    [(ngModel)]="selectedColumns"
    (ngModelChange)="storeSettings()"
    style="max-height: 70vh"
  >
    @for (column of allColumns; track column.name) {
      <mat-list-option
        color="primary"
        [value]="column.name"
        (click)="$event.stopPropagation()"
        onKeyDown="$event.stopPropagation()"
      >
        {{ column.desc }}
      </mat-list-option>
    }
  </mat-selection-list>
</mat-menu>
